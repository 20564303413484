import {DeleteIcon, DownloadIcon} from "@chakra-ui/icons";
import {
  TableContainer,
  CardBody,
  Card,
  Button,
  useToast,
  Box, Tag,
} from '@chakra-ui/react'
import {useNavigate} from "react-router-dom";
import UAParser from "ua-parser-js";
import {DataTable} from "../components/dataTable";
import {createColumnHelper} from "@tanstack/react-table";
import {useAppDataStore} from "../mobx/appDataStore";
import {useEffect, useMemo, useRef, useState} from "react";

const columnHelper = createColumnHelper();


const LoginAttempts = () => {
  const appDataStore = useAppDataStore()
  const {socket} = appDataStore
  const [refreshDependencies, setRefreshDependencies] = useState(0)
  const navigate = useNavigate()
  useEffect(() => {
    document.title = 'Inicios de sesión - Tienda digital'
  }, []);

  const dataFetch = async (
    records,
    currentPage,
    order,
    search,
    filter,
    attributes
  ) => {
    return new Promise(resolve => {
      const args = {
        model: 'LoginAttempt',
        records,
        currentPage,
        order,
        search,
        filter,
        attributes,
        include: [{model: 'LoginLink', attributes: ['username', 'password', 'profile']}]
      }

      socket.emit('datasource', args, (res) => {
        resolve(res)
      })
    })
  };

  useEffect(() => {
    socket.on('login/event', fetchData)

    return () => {
      socket.off('login/event', fetchData)
    }
  }, []);


  const fetchData = () => {
    setRefreshDependencies(Math.random())
  }

  const columns = useMemo(() => ([
    columnHelper.accessor('id',
      {cell: (info) => info.getValue(), header: "ID"}),
    columnHelper.accessor('platform',
      {cell: (info) => info.getValue(), header: "P"}),
    columnHelper.accessor('LoginLink.username',
      {
        cell: (info) => {
          const value = info.getValue()
          return <Button colorScheme='blue' variant='ghost' onClick={() => {
            navigator.clipboard.writeText(value);
          }}>{value}</Button>
        }, header: "usuario"
      }),
    columnHelper.accessor('LoginLink.password',
      {cell: (info) => info.getValue(), header: "contraseña"}),
    columnHelper.accessor('LoginLink.profile',
      {cell: (info) => info.getValue(), header: "perfil"}),
    columnHelper.accessor('tvCode',
      {cell: (info) => info.getValue(), header: "Codigo"}),
    columnHelper.accessor('sourceIp',
      {cell: (info) => info.getValue(), header: "IP"}),
    columnHelper.accessor('deviceName',
      {cell: (info) => info.getValue(), header: "TV"}),
    columnHelper.accessor('status',
      {
        cell: (info) => {
          const value = info.getValue()
          switch (value) {
            case 'SUCCESS':
              return <Tag variant='outline' color={'green'} colorScheme='green'>EXITOSO</Tag>
            case 'ERROR': {
              if (info.row.original.soundAlert) return <Tag variant='outline' color={'red'}
                                                            colorScheme='red'>ERROR</Tag>
              return <Tag variant='outline' color={'orange'} colorScheme='orange'>ALERTA</Tag>

            }
          }
          return <Tag color={'gray'} variant='outline' colorScheme='gray'>PROCESANDO...</Tag>
        }, header: "Estado"
      }),
    columnHelper.accessor('message',
      {cell: (info) => info.getValue(), header: "Mensaje"}),
    columnHelper.accessor('linkId',
      {cell: (info) => info.getValue(), header: "LinkID"}),
    columnHelper.accessor('createdAt',
      {cell: (info) => Object.datetime(info.getValue()), header: "fecha"}),
    columnHelper.accessor('ipData',
      {
        cell: (info) => {
          const row = info.getValue()
          if (!row || row.status === 'fail') return '-'
          return `${row.country}/${row.city}`
        }, header: "Ubicación"
      }),
    columnHelper.accessor('ipData',
      {
        cell: (info) => {
          const row = info.row.original
          const parser = new UAParser(row.userAgent);
          const userAgent = parser.getResult();

          return `${userAgent.device.type || ''} ${userAgent.device.vendor || ''} ${userAgent.device.model || ''} ${userAgent.os.name || ''} ${userAgent.os.version || ''}`
        }, header: "Dispositivo"
      }),
  ]), [])


  return (
    <Card marginTop={5} marginLeft={5}>
      <CardBody>
        <Button marginLeft={2} colorScheme='blue' onClick={() => navigate('/admin/digitalAccounts/files')} mb={5}>
          Ver Archivos
        </Button>
        <Button marginLeft={2} colorScheme='blue' onClick={() => navigate('/admin/digitalAccounts/list')} mb={5}>
          Ver Cuentas
        </Button>
        <TableContainer overflowX={'visible'} whiteSpace={'pre-wrap'} maxWidth={'1fr'}>
          <DataTable size={'sm'} columns={columns} dataFetch={dataFetch} refreshDependencies={refreshDependencies}/>
        </TableContainer>
      </CardBody>
    </Card>
  )
}

export default LoginAttempts