import {Outlet} from "react-router-dom";
import React, {useEffect, useState} from "react";
import fondo from "../../assets/p_video/fondo.jpg"
import logo from "../../assets/cuentas/logo-png.webp"
import paso1 from "../../assets/p_video/paso1.png"
import paso2 from "../../assets/p_video/paso2.png"
import paso3 from "../../assets/p_video/paso3.png"
import paso4 from "../../assets/p_video/paso4.png"
import paso5 from "../../assets/p_video/paso5.png"
import identificarse from "../../assets/p_video/identificarse.png"
import pasoapaso from "../../assets/p_video/paso_a_paso.png"
import visualizacodigo from "../../assets/p_video/visualiza_codigo.png"
import cortecelular from "../../assets/p_video/corte_celular.png"
import fondo_mobile from "../../assets/p_video/fondo_mobile.jpg"
import {Box, Button, Card, Center, Grid, Hide, Image, Show, SimpleGrid} from "@chakra-ui/react";

const setBackground = () => {

  // Create our stylesheet
  const style = document.createElement('style')
  style.innerHTML =
    `body {
      background-image: url("${fondo}");
      color: purple;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: contain;
  }
  
   @media screen and (max-width: 62em) {
   
      body {
      background-image: url("${fondo_mobile}");
            background-size: contain;
      } 
   }
  `

// Get the first script tag
  const ref = document.getElementById('root')

// Insert our new styles before the first script tag
  ref.parentNode.insertBefore(style, ref)
}

const LayoutAmazon = () => {

  const [ready, setReady] = useState(false)
  useEffect(() => {
    document.title = 'Tienda Digital P. Video'
    setBackground()
  }, []);


  return (
    <Box>
      <Box hideBelow={'lg'}>
      <SimpleGrid columns={[1, 2, 3, 3]} spacing={10}>
        <div>
          <Image src={pasoapaso}/>
          <Image src={paso1}/>
          <Box>
            <Center>
            <Image src={paso2}/>
            </Center>
            <Center>
              <Image src={identificarse}/>
            </Center>
          </Box>
        </div>
        <div>
          <Center>
            <Box w={['100%', '100%', '100%']} pt={50}>
              <Center> <Image src={logo}/>
              </Center>
              <Card
                boxShadow='dark-lg'
                color={'#FFFFFF'}
                background={'rgba(0, 61, 105, 0.5)'}
                padding={0}
              >
                <Outlet/>
              </Card>
            </Box>
          </Center>
        </div>
      </SimpleGrid>
      <SimpleGrid columns={[2, 2, 2, 2]} spacing={10}>
        <Box>
          <Center >
            <Image src={paso3}/>
            <Image src={visualizacodigo}/>
          </Center>
        </Box>
        <SimpleGrid columns={[2, 2, 2, 2]} spacing={10}>
        <Box>

          <Image src={paso4}/>
          <Image src={cortecelular}/>
        </Box>
        <div>
          <Image src={paso5}/>
        </div>
        </SimpleGrid>
      </SimpleGrid>
      </Box>
      <Box hideFrom={'lg'}>
        <Center pt={35}>
            <Box w={['90%', '90%', '90%']} pt={50}>
              <Center>
                <Image src={logo}/>
              </Center>
              <Card
                boxShadow='dark-lg'
                color={'#FFFFFF'}
                background={'rgba(0, 61, 105, 0.5)'}
                padding={0}
              >
                <Outlet/>
              </Card>
            </Box>
        </Center>
        <Center>
        <SimpleGrid columns={[1, 1, 2, 2]}>
          <Image src={paso1}/>
          <Box></Box>
          <Image src={identificarse}/>
          <Image src={paso2}/>

          <Image src={paso3}/>
          <Image src={visualizacodigo}/>
          <Image src={paso4}/>
          <Image src={cortecelular}/>
          <Image src={paso5}/>
        </SimpleGrid>
        </Center>
      </Box>
    </Box>
  )

}

export default LayoutAmazon