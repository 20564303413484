import {Outlet} from "react-router-dom";
import React, {useEffect, useState} from "react";
import fondo from "../../assets/netflix_c/fondo.webp"
import logo from "../../assets/cuentas/logo-png.webp"
import howToCel from "../../assets/netflix_c/intro_mobile.webp"
import howToPc from "../../assets/netflix_c/intro.webp"

import {Box, Button, Card, Center, Image, SimpleGrid} from "@chakra-ui/react";

const setBackground = () => {

  // Create our stylesheet
  const style = document.createElement('style')
  style.innerHTML =
    `body {
      background-image: url("${fondo}");
      color: purple;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
  }
  
   @media screen and (max-width: 815px) {
   
      body {
            background-size: contain;
      } 
   }
  `

// Get the first script tag
  const ref = document.getElementById('root')

// Insert our new styles before the first script tag
  ref.parentNode.insertBefore(style, ref)
}

const LayoutNetflixCode = () => {

  const [ready, setReady] = useState(false)
  useEffect(() => {
    document.title = 'Tienda Digital Netflix'
    setBackground()
  }, []);

  if (!ready) {
    return (
      <Box>
        <Center>
          <Box hideBelow='lg' paddingTop={10} paddingX={40}>
            <img src={howToPc}/>
          </Box>
          <Box hideFrom={'lg'} paddingTop={10} paddingX={10}>
            <img src={howToCel}/>
          </Box>

        </Center>
        <Center>
          <Box paddingY={7} display={'block'}>
            <Button onClick={() => setReady(true)} paddingX={20}>Continuar</Button>
          </Box>
        </Center>
      </Box>
    )
  }
  return (
    <>
      <Center>
        <Box w={['95%', '80%', 700]} pt={50}>
          <Center><Image alt={'logo'} src={logo}/>
          </Center>
          <Card
            borderRadius={30}
            boxShadow='dark-lg'
            border={'0px'}
            borderColor={'#FFFFFF'}
            color={'#FFFFFF'}
            background={'rgba(3, 0, 44, 0.5)'}
          >
            <Outlet/>
          </Card>
        </Box>
      </Center>
    </>
  )

}

export default LayoutNetflixCode