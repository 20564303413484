import {Outlet} from "react-router-dom";
import React, {useEffect} from "react";
import fondo from "../../assets/freefire/fondo.jpg"
import fondo_mobile from "../../assets/freefire/mobile.jpg"
import {Box, Card, Center, Grid, GridItem, VStack} from "@chakra-ui/react";

const setBackground = () => {

  // Create our stylesheet
  const style = document.createElement('style')
  style.innerHTML =
    `body {
      background: url("${fondo}")  no-repeat center center fixed;
      color: white;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
  }
  
   @media screen and (max-width: 62em) {
   
      body {
      background-image: url("${fondo_mobile}");
            background-size: cover;
      } 
   }
  `

// Get the first script tag
  const ref = document.getElementById('root')

// Insert our new styles before the first script tag
  ref.parentNode.insertBefore(style, ref)
}

const LayoutFreeFire = () => {

  useEffect(() => {
    document.title = 'Tienda Digital Free Fire'
    setBackground()
  }, []);


  return (
    <>

      <Grid
        h='100vh'
        templateRows='repeat(3, 1fr)'
        templateColumns='repeat(5, 1fr)'
        gap={10}
      >
        <GridItem rowSpan={3} colSpan={1} bg=''/>
        <GridItem colSpan={4} bg=''></GridItem>
        <GridItem colSpan={4} bg=''>
          <Center h={'100%'} backgroundColor={''}>
            <Outlet/>
          </Center>
        </GridItem>
        <GridItem colSpan={4} bg=''>

        </GridItem>
      </Grid>

    </>
  )

}

export default LayoutFreeFire