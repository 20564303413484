import React from "react";
import Button from "../../components/button";
import {Box, CardBody, Center, Image, SimpleGrid} from "@chakra-ui/react";
import {useAppDataStore} from "../../mobx/appDataStore";
import thanks from "../../assets/thanks.jpeg"
import error from "../../assets/error.jpeg"

const Screen = ({service, fetchData}) => {
  const appDataStore = useAppDataStore()

  return (
    <CardBody>
      <Center>
        <SimpleGrid>
          <Box>
            <Image src={service.status === 'error' ? error : thanks}/>
          </Box>
        </SimpleGrid>
      </Center>
    </CardBody>
  )
}

export default Screen