import {Box, Button, Center, FormLabel, Input, useToast} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useAppDataStore} from "../../mobx/appDataStore";
import {useFormik} from "formik";
import ErrorModal from "../../components/errorModal";

const options = {
  1: '100+10 Diamonds',
  2: '310+31 Diamonds',
  3: '520+52 Diamonds',
  4: '1060+106 Diamonds',
  5: '2180+218 Diamonds'
}

export default function TopupFreeFire() {
  const params = useParams()
  const appDataStore = useAppDataStore()
  const [linkInfo, setLinkInfo] = useState()

  const toast = useToast()

  const formik = useFormik({
    initialValues: {playerId: ''},
    onSubmit: async (values) => {
      try {
        const res = await appDataStore.post('/freefire/activate', {linkId: params.code, ...values})
        await handleResponse(res)
      } catch (err) {
        await handleResponse(err.response.data)
      }
    }
  })

  const getLinkInfo = async () => {
    try {
      const res = await appDataStore.post('/freefire/validate', {linkId: params.code})
      setLinkInfo(res)
    } catch (err) {

    }
  }

  const handleResponse = async (res) => {
    toast({
      title: res.error,
      description: res.message,
      status: res.result,
      duration: 9000,
      isClosable: true,
    })
    await getLinkInfo()
  }

  useEffect(() => {
    getLinkInfo()
  }, []);

  return (<Box w={500}>

    <Center>
      <FormLabel>{options[linkInfo?.productId]}</FormLabel>
    </Center>
    {linkInfo?.usedAt ? <>
        <Center>
          <Box fontSize={25}>La recarga ha sido exitosa!</Box>
        </Center>
        <Center>
          <Box>Fecha: <b>{linkInfo?.usedAt}</b></Box>
        </Center>
        <Center>
          <Box>ID de jugador: <b>{linkInfo?.playerId}</b></Box>
        </Center>
        <Center>
          <Box>Nombre de usuario: <b>{linkInfo?.playerName}</b></Box>
        </Center>
      </> :
      <>
        <Center>
          <FormLabel>ID DEL JUGADOR</FormLabel>
        </Center>
        <Center>
          <Input value={formik.values.playerId}
                 width={250}
                 name={'playerId'}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 color={'#5C3381'}
                 textAlign={'center'}
                 fontWeight={'bold'}
                 backgroundColor={'#FFF'}
          />
        </Center>
        <Center>
          <Button marginTop={3} isLoading={formik.isSubmitting} onClick={formik.submitForm}>Recargar</Button>
        </Center>
      </>
    }
  </Box>)
}