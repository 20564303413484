import React, {createContext, useContext} from 'react';
import Axios from 'axios';

import {
  observable,
  makeObservable,
  reaction,
  runInAction, action,
} from 'mobx';

import io from "socket.io-client";
import {matchPath} from "react-router";
import notification from "../assets/notification.wav";
import alert from "../assets/alert.wav";

const AppDataStore = function AppDataStore() {

  const alerts = {
    notification: new Audio(notification),
    alert: new Audio(alert)
  }

  this.session = null;
  this.user = null
  this.admin = false
  this.unAuthorizedAdmin = false

  this.headerData = {
    availableAccounts: 0,
    extensions: 0,
    alerts: 0
  }
  this.alerts = []

  this.socket = null

  const match = matchPath({path: "/store/:token/*"}, window.location.pathname);

  const axios = Axios.create({baseURL: '/api/v1'});

  this.get = async (endpoint, config = {}) => {
    try {
      if (this.user?.token) {
        if (!config.headers) config.headers = {};
        config.headers.authorization = `Token ${this.user.token}`;
      }
      const res = await axios.get(endpoint, config);
      const {data} = res;
      return data;
    } catch (error) {
      if (error.response.data) {
        //Alert(error.response.data.error);
        throw new Error(error.response.data.error);
      }
      throw error;
    }
  };

  this.post = async (endpoint, post, config = {}) => {
    try {
      if (this.user?.token) {
        if (!config.headers) config.headers = {};
        config.headers.authorization = `Token ${this.user.token}`;
      }

      const res = await axios.post(endpoint, {data: post}, config);
      const {data} = res;
      return data;
    } catch (error) {
      if (error.response.data) {
        //Alert(error.response.data.error);
        const err = new Error(error.response.data.error);
        err.response = error.response
        throw err
      }
      throw error;
    }
  };

  this.login = async ({email, password, otp, recaptcha}) => {
    const res = await this.post('/auth/login', {
      email,
      password,
      recaptcha,
      otp
    });
    if (res.user) await this.updateUser(res.user);
    return res;
  };

  this.updateUser = async (user) => {

    runInAction(() => {
      this.unAuthorizedAdmin = false
      if (this.user === null) return (this.user = user);
      return Object.keys(user).forEach((key) => {
        if (this.user[key] !== user[key]) {
          this.user[key] = user[key];
        }
      });
    });
    return user;
  };

  this.logout = async () => {
    try {
      await this.get('/auth/logout');
    } finally {
      runInAction(() => {
        this.user = null;
        this.unAuthorizedAdmin = true
      });
    }
  };

  this.setHeaderData = (availableAccounts, alerts) => {
    this.headerData.availableAccounts = availableAccounts
    this.headerData.alerts = alerts
  }

  this.setAdmin = () => {
    this.admin = true
    if (this.unAuthorizedAdmin) this.logout()
  }
  makeObservable(this, {
    session: observable,
    user: observable,
    headerData: observable,
    alerts: observable,
    setHeaderData: action,
    setAdmin: action,
    unAuthorizedAdmin: observable,
  });

  const socketEvents = () => {

    this.socket.on('unauthorized', () => {
      this.unAuthorizedAdmin = true
    })

    this.socket.on('extensions/update', (data) => {
      runInAction(() => {
        this.headerData.extensions = data.connected
        if (data.connected === 0) alerts.alert.play()
      });
    })

    this.socket.on('login/event', (data) => {
      runInAction(() => {
        this.alerts.push(data)
        if (data.status === 'ERROR' && data.soundAlert) alerts.notification.play()
      });
    })

  }

  const loadPersistentData = () => {
    const localSession = localStorage.getItem('session');
    if (localSession && localSession !== 'undefined') this.session = JSON.parse(localSession);

    const localUser = localStorage.getItem('user');
    if (localUser && localUser !== 'undefined') this.user = JSON.parse(localUser);

    let token = ''

    const {origin, pathname} = window.location
    let url = origin
    if (origin.indexOf('localhost') >= 0)
      url = 'http://localhost:7890'
    let namespace = 'guest'

    if (pathname.indexOf('/admin') === 0) {
      namespace = 'admin'
      token = this.user?.token
    }
    if (match) {
      namespace = 'store'
      token = match.params.token
    }

    this.socket = io(`${url}/${namespace}`, {
      autoConnect: true,
      auth: {token}
    });
    socketEvents()
  };

  loadPersistentData();

  setInterval(() => {
    //if (this.headerData.extensions === 0) alerts.alert.play()
  }, 20000)
};

export const appDataStore = new AppDataStore();
export const AppDataContext = createContext(null);
export const useAppDataStore = () => useContext(AppDataContext);
export const AppDataProvider = ({children}) => (
  <AppDataContext.Provider value={appDataStore}>
    {children}
  </AppDataContext.Provider>
);

reaction(
  () => JSON.stringify(appDataStore.session),
  (varStr) => {
    localStorage.setItem('session', varStr);
  }
);
reaction(
  () => JSON.stringify(appDataStore.user),
  (varStr) => {
    localStorage.setItem('user', varStr);
  }
);