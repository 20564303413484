import React, {useEffect, useState} from "react";
import fondo from "../../assets/top-movie/fondo3.png"
import './style.css'

import {
  Box,
  Button,
  Center, Flex,
  FormControl,
  GridItem, Heading,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import banner2 from "../../assets/top-movie/banner2.png"
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useLocation} from "react-router";

const setBackground = () => {

  // Create our stylesheet
  const style = document.createElement('style')
  style.innerHTML =
    `body {
      background: url("${fondo}")  no-repeat center center fixed;
      color: white;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
  }
  
   @media screen and (max-width: 62em) {
   
      body {
      background-image: url("${fondo}");
            background-size: cover;
      } 
   }
  `

// Get the first script tag
  const ref = document.getElementById('root')

// Insert our new styles before the first script tag
  ref.parentNode.insertBefore(style, ref)
}

const CustomerDataTopMovie = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [customerName, setCustomerName] = useState()
  const [customerLastname, setCustomerLastname] = useState()
  const [customerPhone, setCustomerPhone] = useState()
  const location = useLocation()
  const {code} = location.state
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Redimir GiftCard - Top Movie'
    setBackground()
  }, []);

  useEffect(() => {
    if (!code) navigate('./top-movie')
  }, [code]);

  const redeem = async () => {
    setIsLoading(true)
    setErrorMessage('')
    try {
      const res = await axios.post('/api/v1/gift-cards/activate', {
        data: {
          code,
          customerPhone,
          customerName,
          customerLastname
        }
      })
      debugger
      navigate('../top-movie/redeem', {state: {...res.data, code}})
    } catch (err) {
      setErrorMessage(err.response.data.error)
    }
    setIsLoading(false)
  }

  const validateRenew = async () => {
    setIsLoading(true)
    setErrorMessage('')
    try {
      const res = await axios.post('/api/v1/gift-cards/validate-renew', {
        data: {
          code,
          customerPhone,
          customerName,
          customerLastname
        }
      })
      debugger
      return navigate('../top-movie/renew', {
        state: {
          giftCardRedeem: res.data.giftCardRedeem,
          code,
          customerPhone,
          customerName,
          customerLastname
        }
      })
    } catch (err) {
    }
    return redeem()
  }

  return (
    <>
      <Flex align={'end'} justify={'end'}>
        <img style={{maxHeight: 170}} src={banner2}/>
      </Flex>
      <SimpleGrid
        h='70vh'
        columns={{base: 1, sm: 1, md: 1}}
        w={'95vw'}
      >
        <GridItem>
          <Box>
            <form>
              <FormControl className={'mb-2 mt-3'}>
                <Center>
                  <Box display={'block'} justifyContent={'center'} textAlign={'center'}>
                    <Heading>Nombres:</Heading>
                    <Input backgroundColor={'#FFF'} borderRadius={'20px'} boxShadow={'20px'} marginBottom={'20px'}
                           value={customerName} onChange={(e) => setCustomerName(e.target.value)}
                           marginTop={'5px'} color={'#442670'} fontSize={'xx-large'} textAlign={'center'}
                           fontWeight={'bold'}
                           size={'lg'}/>
                    <Heading>Apellidos:</Heading>
                    <Input backgroundColor={'#FFF'} borderRadius={'20px'} boxShadow={'20px'} marginBottom={'20px'}
                           value={customerLastname} onChange={(e) => setCustomerLastname(e.target.value)}
                           marginTop={'5px'} color={'#442670'} fontSize={'xx-large'} textAlign={'center'}
                           fontWeight={'bold'}
                           size={'lg'}/>
                    <Heading>Número de Whatspp:</Heading>
                    <Input backgroundColor={'#FFF'} borderRadius={'20px'} boxShadow={'20px'} marginBottom={'20px'}
                           value={customerPhone} onChange={(e) => setCustomerPhone(e.target.value)}
                           marginTop={'5px'} color={'#442670'} fontSize={'xx-large'} textAlign={'center'}
                           fontWeight={'bold'}
                           size={'lg'} type={'number'}/>
                    <Button
                      size={'lg'}
                      borderRadius={50}
                      marginTop={3}
                      color={'#418fa6'}
                      backgroundColor={'#FFFFFF'}
                      borderColor={'#FFFFFF'}
                      colorScheme=''
                      onClick={validateRenew}
                      variant='outline'
                      loadingText='...'
                      isLoading={isLoading}>
                      CONTINUAR
                    </Button>
                  </Box>
                </Center>
                <Center marginTop={'15px'} color={'red.600'}>{errorMessage}</Center>
              </FormControl>
            </form>
          </Box>
        </GridItem>
      </SimpleGrid>

    </>
  )

}

export default CustomerDataTopMovie