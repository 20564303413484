import {Outlet} from "react-router-dom";
import React from "react";
import logo from "../../assets/logo.png"
import wallpaper1 from "../../assets/backgrounds/clickwallpapers-roblox-game-in-full-hd-img1.jpg"
import wallpaper2 from "../../assets/backgrounds/clickwallpapers-roblox-game-in-full-hd-img2.jpg"
import wallpaper3 from "../../assets/backgrounds/clickwallpapers-roblox-game-in-full-hd-img3.jpg"
import wallpaper4 from "../../assets/backgrounds/clickwallpapers-roblox-game-in-full-hd-img4.jpg"
import wallpaper5 from "../../assets/backgrounds/clickwallpapers-roblox-game-in-full-hd-img5.jpg"
import wallpaper6 from "../../assets/backgrounds/clickwallpapers-roblox-game-in-full-hd-img6.jpg"
import wallpaper7 from "../../assets/backgrounds/clickwallpapers-roblox-game-in-full-hd-img7.jpg"
import wallpaper8 from "../../assets/backgrounds/clickwallpapers-roblox-game-in-full-hd-img8.jpg"
import wallpaper9 from "../../assets/backgrounds/clickwallpapers-roblox-game-in-full-hd-img9.jpg"
import wallpaper10 from "../../assets/backgrounds/clickwallpapers-roblox-game-in-full-hd-img10.png"
import {Box, Card, Center, Image} from "@chakra-ui/react";

const LayoutRoblox = () => {

  const backgrounds = [
    wallpaper1,
    wallpaper2,
    wallpaper3,
    wallpaper4,
    wallpaper5,
    wallpaper6,
    wallpaper7,
    wallpaper8,
    wallpaper9,
    wallpaper10,
  ]

  function getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min) + min)
  }

  const background = backgrounds[getRandomInt(0, backgrounds.length - 1)]

  // Create our stylesheet
  const style = document.createElement('style')

  style.innerHTML =
    `body {
      background-image: url("${background}");
      color: purple;
  }`

// Get the first script tag
  const ref = document.getElementById('root')

// Insert our new styles before the first script tag
  ref.parentNode.insertBefore(style, ref)
  return (
    <Center>
      <Box w={['95%','80%',700]} pt={50}>
        <Card pt={50} pb={50}>
          <Center><Image src={logo} width={300}/></Center>
          <Outlet/>
        </Card>
      </Box>
    </Center>
  )

}

export default LayoutRoblox