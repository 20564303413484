import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Spinner} from "@chakra-ui/react";


const Screen = ({service, message}) => {
  return (
    <div className="center">
      <p>{message}</p>
      <Spinner mb={5} mt={5}/>
    </div>)
}
export default Screen