import {DeleteIcon, DownloadIcon} from "@chakra-ui/icons";
import {
  TableContainer,
  CardBody,
  Card,
  Button,
  useToast,
  Box,
} from '@chakra-ui/react'
import {useNavigate} from "react-router-dom";
import {DataTable} from "../components/dataTable";
import {createColumnHelper} from "@tanstack/react-table";
import {useAppDataStore} from "../mobx/appDataStore";
import {useEffect, useMemo, useRef, useState} from "react";

const columnHelper = createColumnHelper();


const DigitalAccountFiles = () => {
  const appDataStore = useAppDataStore()
  const {socket} = appDataStore
  const toast = useToast()
  const btnCreateRef = useRef()
  const navigate = useNavigate()
  const [refreshDependencies, setRefreshDependencies] = useState(0)

  useEffect(() => {
    document.title = 'Tienda Digital Netflix Archivos'
  }, []);

  useEffect(() => {
    socket.on('links/updated', () => {
      setRefreshDependencies(Math.random())
    })

    return () => {
      socket.off('links/updated')
    }
  }, []);


  const dataFetch = async (
    records,
    currentPage,
    order,
    search,
    filter,
    attributes
  ) => {
    return new Promise(resolve => {
      const args = {
        model: 'LoginLinkUpload',
        records,
        currentPage,
        order,
        search,
        filter,
        attributes,
      }

      socket.emit('datasource', args, (res) => {
        resolve(res)
      })
    })
  };


  const download = (id) => {
    socket.emit('links/files/download', {id}, (res) => {

      const fileData = res.buffer;
      const blob = new Blob([fileData], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = `links_upload_${id}.xlsx`;
      link.href = url;
      link.click();
    })
  }

  const uploadFile = (event) => {
    const files = event.currentTarget.files

    socket.emit('links/upload', {file: files[0], filename: files[0].name}, function (res) {

      if (res.result === 'error') {
        return toast({
          title: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      setRefreshDependencies(Math.random())
    })
  }

  const columns = useMemo(() => ([
    columnHelper.accessor('id',
      {cell: (info) => info.getValue(), header: "ID"}),
    columnHelper.accessor('filename',
      {cell: (info) => info.getValue(), header: "Archivo"}),
    columnHelper.accessor('items',
      {cell: (info) => info.getValue(), header: "Items"}),
    columnHelper.accessor('',
      {
        cell: (info) => <Button color={'green'} onClick={() => download(info.row.original.id)}><DownloadIcon/></Button>,
        header: "Descargar"
      }),
  ]), [])

  return (
    <Card marginTop={5} marginLeft={5}>
      <CardBody>
        <Box display={'none'}>
          <input ref={btnCreateRef} type="file" className={'d-none'} onChange={uploadFile}/>
        </Box>
        <Button colorScheme='blue' onClick={() => btnCreateRef.current.click()} mb={5}>
          Subir archivo
        </Button>
        <Button marginLeft={2} colorScheme='blue' onClick={() => navigate('/admin/digitalAccounts/list')} mb={5}>
          Ver Cuentas
        </Button>
        <TableContainer>
          <DataTable size={'sm'} columns={columns} dataFetch={dataFetch} refreshDependencies={refreshDependencies}/>
        </TableContainer>
      </CardBody>
    </Card>
  )
}

export default DigitalAccountFiles