import {
  TableContainer,
  CardBody,
  Card,
  Button,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Flex,
  Spacer,
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Grid, Tag, Tooltip,
} from '@chakra-ui/react'

import {useNavigate} from "react-router-dom";
import {DataTable} from "../components/dataTable";
import {createColumnHelper} from "@tanstack/react-table";
import {useAppDataStore} from "../mobx/appDataStore";
import {useEffect, useMemo, useRef, useState} from "react";
import {AddIcon, DeleteIcon, EditIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import {useFormik} from "formik";
import moment from "moment";

const columnHelper = createColumnHelper();


const DisplayInfo = ({row, fetchData}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const cancelRef = useRef()
  const appDataStore = useAppDataStore()
  const {socket} = appDataStore
  const [loginLink, setLoginLink] = useState(row)

  const handleClose = () => {
    socket.emit('links/clearUnread', row, function (res) {
    })
    fetchData()
    onClose()
  }

  return (
    <>
      <Button size={'sm'} colorScheme={row.unreadNotes > 0 ? 'red' : 'blue'} onClick={onOpen}>
        <InfoOutlineIcon/>
      </Button>

      <Modal
        size={'6xl'}
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Logs {loginLink.username} perfil: {loginLink.profile}</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <Flex>
              <Box p='12'>
                <pre>
                  {loginLink.notes}
                </pre>

              </Box>
              <Spacer/>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose}>Cerrar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const DigitalAccount = () => {
  const appDataStore = useAppDataStore()
  const {socket, setHeaderData} = appDataStore
  const [data, setData] = useState([])
  const toast = useToast()
  const btnCreateRef = useRef()
  const navigate = useNavigate()
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [refreshDependencies, setRefreshDependencies] = useState(0)

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      profile: '',
      profilePassword: '',
      accountNotes: '',
      linkId: ''
    },

    onSubmit: (values) => {
      socket.emit('links/edit', values, function (res) {
        if (res.result === 'error') {
          return toast({
            title: res.error,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        } else {
          setIsEditOpen(false)
          setSelectedRow(null)

          return toast({
            title: 'Editado',
            description: 'El link se ha editado con éxito',
            status: 'success',
            duration: 9000,
            isClosable: true,
          })

        }
      })
    },
  })

  useEffect(() => {
    if (selectedRow) formik.setValues(selectedRow)
  }, [selectedRow]);

  useEffect(() => {
    document.title = 'Tienda Digital Netflix'
  }, []);

  useEffect(() => {
    socket.on('links/updated', () => {
      fetchData()
    })
    return () => {
      socket.off('links/updated')
    }
  }, []);

  const setHidden = async (row) => {
    socket.emit('links/hide', row, function (res) {
      if (res.result === 'error') {
        return toast({
          title: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      } else {
        return toast({
          title: 'Eliminado',
          description: 'El link se ha eliminado con éxito',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      }
    })
  }

  const setRestored = async (row) => {
    socket.emit('links/restore', row, function (res) {
      if (res.result === 'error') {
        return toast({
          title: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      } else {
        return toast({
          title: 'Restaurado',
          description: 'El link se ha restaurado con éxito',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      }
    })
  }

  const setRandom = async (row) => {
    socket.emit('links/randomLink', row, function (res) {

      if (res.result === 'error') {
        return toast({
          title: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      } else {

        if (isEditOpen) {
          formik.setFieldValue('linkId', res.loginLink.linkId)
        }
        return toast({
          title: 'Editado',
          description: 'El link se ha cambiado con éxito',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      }
    })
  }

  const fetchData = () => {
    setRefreshDependencies(Math.random())
    socket.emit('dashboard-data', {}, (res) => {
      setHeaderData(res.availableAccounts, res.unreadNotes)
    })
  }

  const dataFetch = async (
    records,
    currentPage,
    order,
    search,
    filter,
    attributes
  ) => {
    return new Promise(resolve => {
      const args = {
        model: 'LoginLink',
        records,
        currentPage,
        order,
        search,
        filter,
        attributes,
      }

      socket.emit('datasource', args, (res) => {
        resolve(res)
      })
    })
  };

  const uploadFile = (event) => {
    const files = event.currentTarget.files

    socket.emit('links/upload', {file: files[0], filename: files[0].name}, function (res) {

      if (res.result === 'error') {
        return toast({
          title: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    })
  }

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    return toast({
      title: 'Copiado',
      description: text,
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  const onEdit = (row) => {
    setIsEditOpen(true)
    setSelectedRow(row)
  }

  const onEditClose = () => {
    setIsEditOpen(false)
    setSelectedRow(null)
  }

  const columns = useMemo(() => ([
    columnHelper.accessor('id',
      {
        enableColumnFilter: false,
        cell: (info) => {
          const {row} = info
          if (row.original.unreadNotes > 0 && row.getIsPinned() === false) {
            setTimeout(() => {
              row.pin('top', true, true)
            }, 2000)
          }
          return info.getValue()
        }, header: "ID"
      }),
    columnHelper.accessor('platform',
      {cell: (info) => info.getValue(), header: "Plat"}),
    columnHelper.accessor('username',
      {cell: (info) => info.getValue(), header: "Usuario"}),
    columnHelper.accessor('password',
      {cell: (info) => info.getValue(), header: "Clave"}),
    columnHelper.accessor('profile',
      {cell: (info) => info.getValue(), header: "Perfil"}),
    columnHelper.accessor('profilePassword',
      {cell: (info) => info.getValue(), header: "Pin"}),
    columnHelper.accessor('linkId',
      {
        cell: (info) => {
          if (info.row.original.deletedAt) return ''
          const platform = info.cell.row.original.platform.toLowerCase()
          const linkId = info.cell.row.original.linkId

          const url = `${window.location.origin}/${platform}/${linkId}/`
          return <Button colorScheme='green' size={'sm'} onClick={() => copyToClipboard(url)}>Copiar</Button>
        }, header: "Link"
      }),
    columnHelper.accessor('createdAt',
      {cell: (info) => Object.datetime(info.getValue()), header: "Fecha Creación"}),
    columnHelper.accessor('usedAt',
      {cell: (info) => Object.datetime(info.getValue()), header: "Fecha Uso"}),
    columnHelper.accessor('status',
      {
        cell: ({cell, row}) => {
          if (row.original.deletedAt) return <Tag color={'red'} variant='outline' colorScheme='red'>ELIMINADA</Tag>
          const value = cell.getValue()
          switch (value) {
            case 'AVAILABLE':
              return <Tag variant='outline' colorScheme='blue' color={'blue'}>Disponible</Tag>
            case 'USED':
              return <Tag variant='outline' color={'green'} colorScheme='green'>Usada</Tag>
            case 'WARRANTY':
              return <Tag variant='outline' color={'orange'} colorScheme='orange'>En Garantía</Tag>
          }
          return <Tag color={'gray'} variant='outline' colorScheme='gray'>{value}</Tag>

        }, header: "Estado"
      }),
    columnHelper.accessor('deviceName',
      {
        cell: (info) => {
          const value = info.getValue()
          return value ? <Tooltip label={<div style={{whiteSpace: 'pre-line'}}>{value}</div>} aria-label='A tooltip'
                                  placement='auto-start'>
            {value.substring(0, 15)}
          </Tooltip> : <></>
        }, header: "TV"
      }),
    columnHelper.accessor('unreadNotes',
      {
        cell: (info) => info.getValue(),
        header: "N"
      }),
    columnHelper.accessor('',
      {
        cell: (info) => <DisplayInfo fetchData={fetchData} row={info.row.original}/>,
        header: "Info"
      }),

    columnHelper.accessor('',
      {
        cell: (info) => <Button color={'blue'} colorScheme='teal' variant={'outline'} size={'sm'}
                                onClick={() => onEdit(info.row.original)}><EditIcon/></Button>,
        header: "Editar"
      }),
    columnHelper.accessor('deletedAt',
      {
        enableColumnFilter: false,
        cell: ({cell, row}) => {

          const value = cell.getValue()
          if (value) return (<Button
            color={'green'}
            colorScheme='teal'
            variant={'outline'}
            size={'sm'}
            onClick={() => setRestored(row.original)}><AddIcon/>
          </Button>)

          return <Button color={'red'} colorScheme='teal' variant={'outline'} size={'sm'}
                         onClick={() => setHidden(row.original)}><DeleteIcon/></Button>
        },
        header: "Acción"
      }),
  ]), [])

  useEffect(() => {
    fetchData()
  }, []);

  return (
    <Grid>
      <Card marginTop={5} marginLeft={5}>
        <CardBody>
          <Box display={'none'}>
            <input ref={btnCreateRef} type="file" className={'d-none'} onChange={uploadFile}/>
          </Box>
          <Button colorScheme='blue' onClick={() => btnCreateRef.current.click()} mb={5}>
            Subir archivo
          </Button>
          <Button marginLeft={2} colorScheme='blue' onClick={() => navigate('/admin/digitalAccounts/files')} mb={5}>
            Ver Archivos
          </Button>
          <Button marginLeft={2} colorScheme='blue' onClick={() => navigate('/admin/digitalAccounts/loginAttempts')}
                  mb={5}>
            Ver Notificaciones
          </Button>
          <Button marginLeft={2} colorScheme='blue'
                  onClick={() => navigate('/admin/digitalAccounts/connectedExtensions')}
                  mb={5}>
            Ver Extensiones
          </Button>
          <Button marginLeft={2} colorScheme='blue'
                  onClick={() => navigate('/admin/digitalAccounts/giftCards')}
                  mb={5}>
            Gift Cards
          </Button>
          <TableContainer overflowX={'visible'} whiteSpace={'pre-wrap'} maxWidth={'1fr'}>
            <DataTable size={'sm'} columns={columns} data={data} dataFetch={dataFetch}
                       refreshDependencies={refreshDependencies}
                       defaultSorting={[{id: 'unreadNotes', desc: true}]}/>
          </TableContainer>
        </CardBody>

        <Modal
          isOpen={isEditOpen}
          onClose={onEditClose}
        >
          <ModalOverlay/>
          <ModalContent>
            <ModalHeader>Editar link</ModalHeader>
            <ModalCloseButton/>
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Usuario</FormLabel>
                <Input
                  name={'username'}
                  onChange={formik.handleChange}
                  value={formik.values.username}
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Contraseña</FormLabel>
                <Input
                  name={'password'}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                /> </FormControl>

              <FormControl mt={4}>
                <FormLabel>Perfil</FormLabel>
                <Input
                  name={'profile'}
                  onChange={formik.handleChange}
                  value={formik.values.profile}
                /> </FormControl>

              <FormControl mt={4}>
                <FormLabel>Contraseña Perfil</FormLabel>
                <Input
                  name={'profilePassword'}
                  onChange={formik.handleChange}
                  value={formik.values.profilePassword}
                /> </FormControl>

              <FormControl mt={4}>
                <FormLabel>Notas</FormLabel>
                <Input
                  name={'accountNotes'}
                  onChange={formik.handleChange}
                  value={formik.values.accountNotes}
                /> </FormControl>
              <FormControl mt={4}>
                <FormLabel>Link ID</FormLabel>
                <Input
                  name={'linkId'}
                  value={formik.values.linkId}
                  disabled={true}
                /> </FormControl>

              <FormControl mt={4}>
                <FormLabel>Link ID</FormLabel>
                <Select
                  name={'status'}
                  value={formik.values.status}
                  onChange={formik.handleChange}
                >
                  <option value='AVAILABLE'>Disponible</option>
                  <option value='DISABLED'>Deshabilitada</option>
                  <option value='USED'>Usada</option>
                  <option value='WARRANTY'>Garantia</option>

                </Select>

              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='green' mr={3} onClick={() => setRandom(selectedRow)}>
                Re-generar Link ID
              </Button>
              <Button colorScheme='blue' mr={3} onClick={formik.submitForm}>
                Guardar
              </Button>
              <Button onClick={onEditClose}>Cancelar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Card>
    </Grid>
  )
}

export default DigitalAccount