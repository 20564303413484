import React, {useEffect, useRef, useState} from "react";
import {Carousel} from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import carousel1 from "../../assets/roblox-top-scores.jpg"
import carousel2 from "../../assets/payment-methods/2.jpeg"
import carousel3 from "../../assets/payment-methods/3.jpeg"
import carousel4 from "../../assets/payment-methods/4.jpeg"
import Button from "../../components/button";

import {useAppDataStore} from "../../mobx/appDataStore";
import {useToast} from "@chakra-ui/react";


const Screen = ({service, fetchData}) => {
  const appDataStore = useAppDataStore()
  const {socket} = appDataStore
  const toast = useToast()
  const carouselImages = [
    {src: carousel1, label: 'Recargas'},
    {src: carousel2, label: 'Pagos bancolombia'},
    {src: carousel3, label: 'Pagos Nequi'},
    {src: carousel4, label: 'Pagos Daviplata/Davivienda'}
  ]
  const inputRef = useRef()

  const uploadVoucher = () => {
    inputRef.current.click()
  }

  const onChange = async (e) => {
    const [file] = e.target.files
    socket.emit("upload", {file, meta: {name: file.name, mime: file.type, type: 'payment'}}, function (res) {
      if (res.result === 'error') {
        return toast({
          title: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }

      fetchData()
      return toast({
        title: 'Se ha reportado el pago con éxito',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    })
  }

  return (
    <div className="center">
      <Carousel
        className={'p-2 pt-3'}
        showArrows={true}
        infiniteLoop={true}
        swipeable={true}
        emulateTouch={true}
        dynamicHeight={true}
        showThumbs={true}
        showStatus={false}
      >
        {
          carouselImages.map(({label, src}) =>
            <div key={src}>
              <img alt={label} src={src}/>
            </div>
          )
        }
      </Carousel>
      <input
        onChange={onChange}
        ref={inputRef}
        type="file"
        accept="image/*"
        style={{display: 'none'}}
      />
      <Button onClick={uploadVoucher} title={'Adjuntar comprobante de pago'}/>
      <p>&nbsp;</p>
    </div>)
}
export default Screen