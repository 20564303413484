import React, {useEffect, useState} from "react";
import {Carousel} from "react-responsive-carousel";
import carousel1 from "../../assets/login-steps/1.jpeg";
import carousel2 from "../../assets/login-steps/2.jpeg";
import carousel3 from "../../assets/login-steps/3.jpeg";
import Button from "../../components/button";
import {Box, Card, CardBody, Center} from "@chakra-ui/react";
import {useAppDataStore} from "../../mobx/appDataStore";

const Screen = ({service, fetchData}) => {
  const appDataStore = useAppDataStore()
  const {socket} = appDataStore
  const [ticker, setTicker] = useState(-1)
  const [expired, setExpired] = useState(false)
  const timeOut = 10

  const carouselImages = [
    {src: carousel1, label: ''},
    {src: carousel2, label: ''},
    {src: carousel3, label: ''},
  ]

  const tickerToString = (time) => {
    const minutes = `0${Math.floor(time / 60)}`.substr(-2);
    const seconds = `0${time - minutes * 60}`.substr(-2)

    if (ticker > 0) return `00:${minutes}:${seconds}`
    return 'código expirado'
  }

  const requestCodeOld = () => {
    setExpired(false)
    setTicker(timeOut)
    let ct = timeOut
    const interval = setInterval(() => {
      if (ct === 0) {
        setExpired(true)
        return clearInterval(interval)
      }
      setTicker(--ct)
    }, 1000)
  }

  const requestCode = () => {
    socket.emit('services/code/request', {},(data) => {
      fetchData()
    })
  }

  return (

    <CardBody>
      <p>Sigue los siguientes pasos para ingresar el código de inicio de sesión</p>
      {ticker > 0 ?
        <div>
          <img className="login-code-img" alt='codigo' src={'example.png'}/>
          <p className="counter">{tickerToString(ticker)}</p>
        </div> :
        <div>
          <Carousel
            className={'p-2 pt-3'}
            autoPlay={true}
            transitionTime={3}
            showArrows={true}
            infiniteLoop={true}
            swipeable={true}
            emulateTouch={true}
            dynamicHeight={false}
            showThumbs={false}
            showStatus={false}
          >
            {
              carouselImages.map(({label, src}) =>
                <div key={src}>
                  <img alt={label} src={src}/>
                </div>
              )
            }
          </Carousel>
          <Button onClick={requestCode} title="Solicitar código"/>
        </div>
      }
      <div>
        {expired ? <div>
          <p className="counter">Código expirado</p>
          <Button title="Iniciar con correo y contraseña"/>
        </div> : null}

      </div>
    </CardBody>
  )
}

export default Screen