import React, {useEffect, useState} from "react";
import ProcessScreen from "./processScreen";
import PaymentScreen from "./paymentScreen";
import WaitingScreen from "./waitingScreen";
import CompletedScreen from "./completedScreen"

import {useAppDataStore} from "../../mobx/appDataStore";
import {Center, Spinner, useToast} from "@chakra-ui/react";
import CodeGeneratedScreen from "./codeGeneratedScreen";
import PersonalData from "./personalData";

const Roblox = () => {
  const appDataStore = useAppDataStore()
  const toast = useToast()
  const {socket} = appDataStore
  const [service, setService] = useState(null)

  const fetchData = () => {
    socket.emit('services/get', {}, function (res) {
      if (res.result === 'error') {
        return toast({
          title: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      setService(res.service)
    })
  }

  useEffect(() => {
    fetchData()
    socket.on('services/updated', () => {
      fetchData()
    })

    return () => {
      socket.off('services/updated')
    }

  }, []);
  switch (service?.status) {
    case 'personalData':
      return <PersonalData service={service} fetchData={fetchData}/>
    case 'created':
      return <PaymentScreen service={service} fetchData={fetchData}/>
    case 'paymentReported':
      return <WaitingScreen service={service} message={'por favor espera, se está validando tu pago'} fetchData={fetchData}/>
    case 'paid':
      return <ProcessScreen service={service} fetchData={fetchData}/>
    case 'codeRequested':
      return <WaitingScreen service={service} message={'Por favor espera, se está generando el código para iniciar sesión'} fetchData={fetchData}/>
    case 'codeSent':
      return <CodeGeneratedScreen service={service} fetchData={fetchData}/>
    case 'codeReady':
      return <WaitingScreen service={service} message={'por favor espera, se estan adquiriendo tus Robux'} fetchData={fetchData}/>
    case 'completed':
      return <CompletedScreen service={service} fetchData={fetchData}/>
    case 'error':
      return <CompletedScreen service={service} fetchData={fetchData}/>

    default:
      return <Center p={10}><Spinner/></Center>
  }
}

export default Roblox