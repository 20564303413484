import React, {useEffect, useState} from "react";
import fondo from "../../assets/top-movie/fondo.png"
import './style.css'

import {
  Box,
  Button,
  Center,
  FormControl,
  GridItem,
  HStack,
  Image,
  PinInput,
  PinInputField,
  SimpleGrid,
} from "@chakra-ui/react";
import banner from "../../assets/top-movie/banner.png"
import header from "../../assets/top-movie/header.png"
import {useNavigate} from "react-router-dom";
import axios from "axios";

const setBackground = () => {

  // Create our stylesheet
  const style = document.createElement('style')
  style.innerHTML =
    `body {
      background: url("${fondo}")  no-repeat center center fixed;
      color: white;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
  }
  
   @media screen and (max-width: 62em) {
   
      body {
      background-image: url("${fondo}");
            background-size: cover;
      } 
   }
  `

// Get the first script tag
  const ref = document.getElementById('root')

// Insert our new styles before the first script tag
  ref.parentNode.insertBefore(style, ref)
}

const IndexTopMovie = () => {
  const [code, setCode] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Redimir GiftCard - Top Movie'
    setBackground()
  }, []);


  const next = async () => {
    setIsLoading(true)
    setErrorMessage('')
    try {
      const res = await axios.post('/api/v1/gift-cards/validate', {data: {code}})

      if (res.data.status === 'AVAILABLE') {
        navigate('./customer-data', {state: {code}})
      }

      if (res.data.status === 'REDEEM') {

        navigate('../top-movie/redeem', {state: {...res.data.accountData, code}})
      }
    } catch (err) {
      setErrorMessage(err.response.data.error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <SimpleGrid
        h='95vh'
        columns={{base: 1, sm: 1, md: 2}}
        w={'95vw'}
      >
        <GridItem rowSpan={2} colSpan={1} bg=''>
          <Center>
            <Image src={header} maxWidth={{base: '100%', lg: '80%'}} alt={'Has adquirido una gift card'}/>
          </Center>
          <Center>
            <Image src={banner} maxWidth={{base: '100%', lg: '80%'}} alt={'Has adquirido una gift card'}/>
          </Center>
          <Box position={'relative'} top={'-40px'}>
            <Center>
              <Box maxWidth={'70%'} padding={'40px'} background={'blackAlpha.600'} borderRadius={'35px'}
                   fontSize={{base: '1em', lg: 'larger'}}
                   fontWeight={'bold'}
              >
                <Center style={{textAlign: 'center'}}>
                  Para disfrutar de tu contenido, ingresa el PIN de Diez (10) dígitos alfanuméricos que
                  se encuentra interno en tu GIFT CARD fisica
                </Center>
              </Box>

            </Center>
          </Box>
        </GridItem>
        <GridItem>
          <Center>
            <Box
              borderBottomRadius={50}
              backgroundColor={'#FFFFFF'}
              color={'#418fa6'}
              paddingTop={'20px'}
              paddingX={{base: '25px', lg: '50px'}}
              paddingBottom={'50px'}>
              <ul className={'ul-top-movie'}>
                <li><h1>1</h1>Abre tu GiftCard y encuentra el PIN único de acceso.</li>
                <li><h1>2</h1>Ingresa el PIN en este activador</li>
                <li><h1>3</h1>Pulsa sobre el botón ACTIVAR GIFT CARD</li>
                <li><h1>4</h1>Se abrirá una nueva pestaña con:
                  <p>• Tus datos de acceso</p>
                  <p>• Guía de producto</p>
                  <p>• Términos y condiciones</p>
                </li>
                <li><h1>5</h1>¡Y listo! Ingresa a la plataforma oficial y disfruta de tu contenido</li>
              </ul>
            </Box>
          </Center>
        </GridItem>
        <GridItem>
          <Box>
            <form>
              <FormControl className={'mb-2 mt-3'}>
                <Center>
                  <Box borderRadius={50} maxWidth={'100%'} backgroundColor={'#418fa6'} paddingX={6} paddingY={7}>
                    <>
                      <HStack>
                        <PinInput
                          size={'lg'}
                          autoFocus={true}
                          manageFocus={true}
                          placeholder={''}
                          type='alphanumeric'
                          color={'#000000'}
                          backgroundColor={'#FFFFFF'}
                          value={code}
                          onComplete={code => {
                            setCode(code)
                          }}
                        >
                          <PinInputField marginX={1} color={'#000000'} backgroundColor={'#FFFFFF'}/>
                          <PinInputField marginX={1} color={'#000000'} backgroundColor={'#FFFFFF'}/>
                          <PinInputField marginX={1} color={'#000000'} backgroundColor={'#FFFFFF'}/>
                          <PinInputField marginX={1} color={'#000000'} backgroundColor={'#FFFFFF'}/>
                          <PinInputField marginX={1} color={'#000000'} backgroundColor={'#FFFFFF'}/>
                          <PinInputField marginX={1} color={'#000000'} backgroundColor={'#FFFFFF'}/>
                          <PinInputField marginX={1} color={'#000000'} backgroundColor={'#FFFFFF'}/>
                          <PinInputField marginX={1} color={'#000000'} backgroundColor={'#FFFFFF'}/>
                          <PinInputField marginX={1} color={'#000000'} backgroundColor={'#FFFFFF'}/>
                          <PinInputField marginX={1} color={'#000000'} backgroundColor={'#FFFFFF'}/>
                        </PinInput>
                      </HStack>

                      <Center>
                        <Button
                          size={'lg'}
                          borderRadius={50}
                          marginTop={8}
                          color={'#418fa6'}
                          backgroundColor={'#FFFFFF'}
                          borderColor={'#FFFFFF'}
                          colorScheme=''
                          onClick={next}
                          variant='outline'
                          loadingText='Por favor espere...'
                          isLoading={isLoading}>
                          ACTIVAR GIFT CARD
                        </Button>
                      </Center>
                      <Center color={'red.600'}>{errorMessage}</Center>
                    </>
                  </Box>
                </Center>

              </FormControl>
            </form>
          </Box>
        </GridItem>
      </SimpleGrid>

    </>
  )

}

export default IndexTopMovie