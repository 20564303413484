import {
  Box,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  Text, Input, Button, Card, Image, useToast,
} from "@chakra-ui/react";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import CountDown from "../../components/countDown";
import ErrorModal from "../../components/errorModal";
import {useAppDataStore} from "../../mobx/appDataStore";
import n_logo from "../../assets/netflix_c/logo.webp"
import used from "../../assets/netflix_c/used.webp"


export default function GetCode() {
  const params = useParams()
  const [loginData, setLoginData] = useState(null)
  const appDataStore = useAppDataStore()
  const {socket} = appDataStore
  const [result, setResult] = useState()
  const [loading, setLoading] = useState(true)
  const [validatorResponse, setValidatorResponse] = useState(null)
  const toast = useToast()

  const getLinkInfo = async () => {
    setLoading(true)
    socket.emit('netflix/loginCode', {linkId: params.code}, (res) => {
      setValidatorResponse(res)
      setLoading(false)
    })
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    return toast({
      title: 'Copiado',
      description: text,
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  useEffect(() => {
    getLinkInfo()
  }, []);

  useEffect(() => {
    const event = (data) => {
      setLoginData(data)
    }
    socket.on(`netflix/loginCode`, event)
    return () => {
      //socket.off(event)
    }
  }, []);

  useEffect(() => {
    if (validatorResponse && validatorResponse.result === 'busy') {
      setTimeout(getLinkInfo, 10000)
    }
  }, [validatorResponse]);

  if (loading) return (
    <Box>
      <Center>
        <Text>
          Cargando...
        </Text>
      </Center>
    </Box>)

  if (validatorResponse.result === 'used') return (
    <Box padding={10}>
      <Center
        className={'mt-1 mb-2'}
        fontWeight={'bold'}
      >Has adquirido una pantalla (Perfil) de:
      </Center>
      <Center>
        <Image
          alt='logo'
          src={used}
        />
      </Center>
      <Center>
        <Box maxW={'400px'} display={'flex'} mt={4} mb={4}>
          <Input
            textAlign={'center'}
            color={'black'}
            backgroundColor={'white'}
            variant={'outline'}
            contentEditable={false}
            value={validatorResponse.username}/>
          <Button ml={2} onClick={() => copyToClipboard(validatorResponse.username)}>Copiar</Button>
        </Box>
      </Center>
      <Center>
        <Text
          color={'white'}
          backgroundColor={'red'}
          p={1}
          px={2}
          borderRadius={4}>
          Código de inicio de sesión:
        </Text>
        <Text
          pl={3}
          display={'inline'}
          fontSize={'xx-large'}
          fontWeight={'extrabold'}>
          {validatorResponse.loginCode}
        </Text>
        <Button size={'sm'} ml={2} onClick={() => copyToClipboard(validatorResponse.loginCode)}>Copiar</Button>
      </Center>
      <Center>
        <Text
          color={'white'}>
          Fecha de uso:
        </Text>
        <Text
          pl={2}
          fontWeight={'extrabold'}>
          {validatorResponse.usedAt}
        </Text>
      </Center>
      <Center>
        <Text
          color={'white'}>
          Perfil asignado:
        </Text>
        <Text
          pl={2}
          fontWeight={'extrabold'}>
          {validatorResponse.profile}
        </Text>
      </Center>
      <Center>
        <Text color={'white'}> Pin perfil: </Text>
        <Text pl={2}
              fontWeight={'extrabold'}>
          {validatorResponse.pin}
        </Text>
      </Center>
    </Box>)

  if (validatorResponse.result === 'busy') {
    return (<div className="center">
      <Box
        p={{sm: 3, base: 5, lg: 10}}
        pt={1}
      >
        <form>
          <Center
            className={'mt-1 mb-2'}
            fontWeight={'bold'}
          >Has adquirido una pantalla (Perfil) de:
          </Center>
          <Center>
            <Image alt='logo' src={n_logo}/>
          </Center>
          <Box p={3}>
            <p>Por favor espera un momento para continuar</p>
            <CountDown createdAt={moment(validatorResponse.createdAt).unix() * 1000} seconds={120}/>
          </Box>
          {result &&
            <>
              <Alert status={result.status} color={'#000000'}>
                <AlertIcon/>
                <AlertTitle>{result.message}</AlertTitle>
              </Alert>
              <ErrorModal result={result}/>
            </>
          }
        </form>
      </Box>
    </div>)
  }

  return (<div className="center">
    <Box
      p={{sm: 3, base: 5, lg: 10}}
      pt={1}
    >
      <form>
        <Center
          className={'mt-1 mb-2'}
          fontWeight={'bold'}
        >Has adquirido una pantalla (Perfil) de:
        </Center>
        <Center>
          <Image alt='logo' src={n_logo}/>
        </Center>
        <Box p={3}>
          <p>Utiliza la siguiente dirección de correo electrónico para iniciar sesión:</p>
          <Center>
            <Box maxW={'400px'} display={'flex'} mt={4} mb={4}>
              <Input textAlign={'center'} color={'black'} backgroundColor={'white'} variant={'outline'}
                     contentEditable={false}
                     value={validatorResponse.username}/>
              <Button ml={2} onClick={() => copyToClipboard(validatorResponse.username)}>Copiar</Button>
            </Box>
          </Center>

          {loginData ?
            <Box fontSize={'large'} mt={0}>
              <Center>
                <Text color={'white'} backgroundColor={'red'} p={1} px={2} borderRadius={4}> Código de inicio de
                  sesión:</Text> <Text pl={3} display={'inline'} fontSize={'xx-large'}
                                       fontWeight={'extrabold'}>{loginData.loginCode}</Text>
                <Button size={'sm'} ml={2} onClick={() => copyToClipboard(loginData.loginCode)}>Copiar</Button>
              </Center>
              <Center>
                <Text color={'white'}> Perfil asignado: </Text>
                <Text pl={2}
                      fontWeight={'extrabold'}>{loginData.profile}
                </Text>
              </Center>
              <Center>
                <Text color={'white'}> Pin perfil: </Text>
                <Text pl={2}
                      fontWeight={'extrabold'}>
                  {loginData.profilePassword}
                </Text>
              </Center>
            </Box> :
            <Card
              borderRadius={10}
              boxShadow='dark-lg'
              border={'0px'}
              borderColor={'#FFFFFF'}
              color={'#FFFFFF'}
              background={'rgb(213,33,39)'}
            >
              haz click en "Usar un código de inicio de sesión" en netflix para obtener el código
              <Box fontSize={'larger'} fontWeight={'bolder'}>
                <CountDown createdAt={moment(validatorResponse.createdAt).unix() * 1000} seconds={120}/>
              </Box>
            </Card>
          }
        </Box>
        {result &&
          <>
            <Alert status={result.status} color={'#000000'}>
              <AlertIcon/>
              <AlertTitle>{result.message}</AlertTitle>
            </Alert>
            <ErrorModal result={result}/>
          </>
        }
      </form>
    </Box>
  </div>)
}