import './App.css'
import React from 'react'
import {BrowserRouter, Navigate, Outlet} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom';
import DigitalAccount from "./admin/digitalAccount";
import DigitalAccountFiles from "./admin/digitalAccountFiles";
import LoginAttempts from "./admin/loginAttempts";
import LayoutFreeFire from "./products/freeFire/layout";
import TopupFreeFire from "./products/freeFire/topupFreefire";
import LoginTv from "./products/netflix/loginTv";
import LayoutRoblox from "./products/roblox/layout";
import LayoutAdmin from "./admin/layout";
import LayoutNetflix from "./products/netflix/layout"
import Roblox from "./products/roblox";
import {AppDataProvider} from "./mobx/appDataStore";
import {ChakraProvider, extendTheme} from '@chakra-ui/react'
import {appDataStore} from './mobx/appDataStore';
import {observer} from 'mobx-react';
import ServiceList from "./admin/serviceList";
import Login from "./admin/login";
import "./helpers/formatters"
import CreateLink from "./products/roblox/createLink";
import moment from 'moment'
import LayoutAmazon from "./products/amazon/layout";
import LayoutNetflixCode from "./products/netflix-code/layout";
import LoginTvAmazon from "./products/amazon/loginTv";
import ConnectedExtensions from "./admin/connectedExtensions";
import GetCode from "./products/netflix-code/getCode"
import IndexTopMovie from "./products/topMovie";
import RedeemTopMovie from "./products/topMovie/redeem";
import RenewTopMovie from "./products/topMovie/renew";

import GiftCards from "./admin/giftCards";
import GiftCardsCodes from "./admin/giftCardsCodes";
import CustomerDataTopMovie from "./products/topMovie/customerData";
import InfoTopMovie from "./products/topMovie/info";

moment.locale('es');

const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
}
const fonts = {
  heading: `'Ubuntu', sans-serif`,
  body: `'Ubuntu', sans-serif`,
}

const theme = extendTheme({colors, fonts})
const PrivateRoute = observer(() => {
  if (appDataStore.user === null || appDataStore.unAuthorizedAdmin) {
    return <Navigate to="/auth" replace/>;
  }
  return <Outlet/>;
});

const App = props => {
  return (
    <ChakraProvider theme={theme}>
      <AppDataProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/admin" element={<PrivateRoute {...props} />}>
              <Route path="" element={<LayoutAdmin/>}>
                <Route index element={<Navigate to={'digitalAccounts/list'}/>}/>
                <Route path="digitalAccounts/list" element={<DigitalAccount/>}/>
                <Route path="digitalAccounts/files" element={<DigitalAccountFiles/>}/>
                <Route path="digitalAccounts/connectedExtensions" element={<ConnectedExtensions/>}/>
                <Route path="digitalAccounts/giftCards" element={<GiftCards/>}/>
                <Route path="digitalAccounts/giftCardsCodes" element={<GiftCardsCodes/>}/>
                <Route path="digitalAccounts/loginAttempts" element={<LoginAttempts/>}/>
                <Route path="services/list" element={<ServiceList/>}/>
              </Route>
            </Route>

            <Route path="store/:token/roblox/" element={<LayoutRoblox/>}>
              <Route index element={<Navigate to={'payment'}/>}/>
              <Route path="payment" element={<Roblox screen={'created'}/>}/>
              <Route path="waiting" element={<Roblox screen={'paymentReported'}/>}/>
              <Route path="process" element={<Roblox screen={'paid'}/>}/>
            </Route>

            <Route path="auth" element={<Login/>}/>
            <Route path="roblox" element={<LayoutRoblox/>}>
              <Route path="" element={<CreateLink/>}/>
            </Route>
            <Route path="n" element={<LayoutNetflix/>}>
              <Route path=":code" element={<LoginTv/>}/>
            </Route>
            <Route path="netfx" element={<LayoutNetflix/>}>
              <Route path=":code" element={<LoginTv/>}/>
            </Route>
            <Route path="nc" element={<LayoutNetflixCode/>}>
              <Route path=":code" element={<GetCode/>}/>
            </Route>
            <Route path="netfxc" element={<LayoutNetflixCode/>}>
              <Route path=":code" element={<GetCode/>}/>
            </Route>
            <Route path="a" element={<LayoutAmazon/>}>
              <Route path=":code" element={<LoginTvAmazon/>}/>
            </Route>
            <Route path="amzn" element={<LayoutAmazon/>}>
              <Route path=":code" element={<LoginTvAmazon/>}/>
            </Route>
            <Route path="freeFire" element={<LayoutFreeFire/>}>
              <Route path=":code" element={<TopupFreeFire/>}/>
            </Route>

            <Route path="top-movie" element={<IndexTopMovie/>}/>
            <Route path="top-movie/redeem" element={<RedeemTopMovie/>}/>
            <Route path="top-movie/redeem/:webToken" element={<InfoTopMovie/>}/>
            <Route path="top-movie/renew" element={<RenewTopMovie/>}/>
            <Route path="top-movie/customer-data" element={<CustomerDataTopMovie/>}/>
            <Route path="*" element={<div/>}/>
          </Routes>
        </BrowserRouter>
      </AppDataProvider>
    </ChakraProvider>
  )
}

export default App

