import React, {useEffect, useState} from "react";
import fondo from "../../assets/top-movie/fondo2.png"
import './style.css'

import {
  Box, Button,
  Center,
  Grid,
  GridItem,
  Image,
  SimpleGrid, Table, Tbody, Td, Th, Thead, Tr,
} from "@chakra-ui/react";
import logo from "../../assets/top-movie/logo.png"
import header from "../../assets/top-movie/header2.png"
import {useLocation, useParams} from "react-router";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const setBackground = () => {

  // Create our stylesheet
  const style = document.createElement('style')
  style.innerHTML =
    `body {
      background: url("${fondo}")  no-repeat center center fixed;
      color: white;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
  }
  
   @media screen and (max-width: 62em) {
   
      body {
      background-image: url("${fondo}");
            background-size: cover;
      } 
   }
  `

// Get the first script tag
  const ref = document.getElementById('root')

// Insert our new styles before the first script tag
  ref.parentNode.insertBefore(style, ref)
}

const RedeemTopMovie = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  const location = useLocation()
  const {
    giftCardRedeem,
    code,
    customerPhone,
    customerName,
    customerLastname
  } = location.state
  console.log(location.state)

  useEffect(() => {
    document.title = 'Renueva tu cuenta! - Top Movie'
    setBackground()
  }, []);

  const redeem = async () => {
    setIsLoading(true)
    setErrorMessage('')
    try {
      const res = await axios.post('/api/v1/gift-cards/activate', {
        data: {
          code,
          customerPhone,
          customerName,
          customerLastname
        }
      })
      navigate('../top-movie/redeem', {state: {...res.data, code}})
    } catch (err) {
      setErrorMessage(err.response.data.error)
    }
    setIsLoading(false)
  }

  const goToRenew = async (id) => {
    setIsLoading(true)
    setErrorMessage('')
    try {
      const res = await axios.post('/api/v1/gift-cards/activate-renew', {
        data: {
          id,
          code,
          customerPhone,
          customerName,
          customerLastname
        }
      })
      navigate('../top-movie/redeem', {state: {...res.data.accountData, code}})
    } catch (err) {
      setErrorMessage(err.response.data.error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <Grid
        templateColumns='repeat(2, 1fr)'
        gridTemplateColumns={'25% 1fr'}
        w={'95vw'}>
        <GridItem>
          <Center>
            <Image src={logo} alt={'Has adquirido una gift card'}/>
          </Center>
        </GridItem>
        <GridItem>
          <Center>
            <Image src={header} maxWidth={{base: '100%', lg: '80%'}} alt={'Has adquirido una gift card'}/>
          </Center>
        </GridItem>
      </Grid>
      <Box marginBottom={'30px'}>
        <Center>
          <Box maxWidth={'70%'} padding={'40px'} background={'blackAlpha.600'} borderRadius={'35px'}
               fontSize={{base: '1em', lg: 'larger'}}
               fontWeight={'bold'}>
            <Center style={{textAlign: 'center'}}>
              Tienes las siguientes cuentas vigentes disponibles para renovar
            </Center>
            <Center>
              <Table>
                <Thead>
                  <Th>Cuenta</Th>
                  <Th>Vigencia</Th>
                  <Th>Renovar</Th>
                </Thead>
                <Tbody>
                  {giftCardRedeem.map(({id, accountData}) => {
                    return (
                      <Tr>
                        <Td>{accountData.login}</Td>
                        <Td>{accountData.renewal_limit_date}</Td>
                        <Td><Button onClick={() => goToRenew(id)}>Renovar</Button></Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </Center>
            <Center color={'red.600'}>{errorMessage}</Center>

            <Center mt={10}>
              <Button onClick={redeem}>No renovar, quiero una cuenta nueva</Button>
            </Center>
          </Box>
        </Center>
      </Box>
    </>
  )

}

export default RedeemTopMovie
