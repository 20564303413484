import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text, useDisclosure
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";

export default function ErrorModal({result}) {
  let [buttonCount, setButtonCount] = useState(0)
  const {isOpen, onOpen, onClose} = useDisclosure()


  const doCount = () => {
    setButtonCount(10)
  }

  useEffect(() => {
    if (buttonCount > 0)
      setTimeout(() => setButtonCount(prev => prev - 1), 1000)
  }, [buttonCount]);

  useEffect(() => {
    debugger

    if (result?.result === 'error') {
      onOpen()
      doCount()
    }
  }, [result]);

  if (!result) return <></>

  return (
    <Modal colorScheme={'blackAlpha'}
           closeOnOverlayClick={false}
           closeOnEsc={false}
           onClose={onClose}
           isOpen={isOpen}
           isCentered
           size={'xl'}>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader minH={200}>
          <Center>
            <Text marginTop={50} size={'xl'} color={'red'}>{result.message}</Text>
          </Center>
        </ModalHeader>
        <ModalBody></ModalBody>
        <ModalFooter>
          <Button
            isLoading={buttonCount > 0}
            loadingText={`${buttonCount} Cerrar`}
            colorScheme='blue'
            variant='outline'
            onClick={onClose}
          >Cerrar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>)
}