import {DeleteIcon, DownloadIcon} from "@chakra-ui/icons";
import {
  TableContainer,
  CardBody,
  Card,
  Button,
  useToast,
  Box,
} from '@chakra-ui/react'
import {useNavigate} from "react-router-dom";
import {DataTable} from "../components/dataTable";
import {createColumnHelper} from "@tanstack/react-table";
import {useAppDataStore} from "../mobx/appDataStore";
import {useEffect, useRef, useState} from "react";
import {HiRefresh} from "react-icons/hi";

const columnHelper = createColumnHelper();


const GiftCards = () => {
  const appDataStore = useAppDataStore()
  const {socket} = appDataStore
  const [data, setData] = useState([])
  const toast = useToast()
  const btnCreateRef = useRef()
  const navigate = useNavigate()
  useEffect(() => {
    document.title = 'Gift Cards redimidas'
  }, []);


  const columns = [
    columnHelper.accessor('id',
      {cell: (info) => info.getValue(), header: "ID"}),

    columnHelper.accessor('customerName',
      {cell: (info) => info.getValue(), header: "Cliente"}),

    columnHelper.accessor('customerPhone',
      {cell: (info) => info.getValue(), header: "Teléfono"}),

    columnHelper.accessor('customerEmail',
      {cell: (info) => info.getValue(), header: "E-mail"}),

    columnHelper.accessor('ip',
      {cell: (info) => info.getValue(), header: "IP"}),

    columnHelper.accessor('GiftCardCode.code',
      {cell: (info) => info.getValue(), header: "Código"}),

    columnHelper.accessor('GiftCardCode.serial',
      {cell: (info) => info.getValue(), header: "Serial"}),

    columnHelper.accessor('GiftCardCode.typeName',
      {cell: (info) => info.getValue(), header: "Tipo"}),

    columnHelper.accessor('createdAt',
      {cell: (info) => Object.datetime(info.getValue()), header: "Fecha"}),
  ]

  const dataFetch = async (
    records,
    currentPage,
    order,
    search,
    filter,
    attributes
  ) => {
    return new Promise(resolve => {
      const args = {
        model: 'GiftCardRedeem',
        records,
        currentPage,
        order,
        search,
        filter,
        attributes,
        include: [{model: 'GiftCardCode', attributes: ['code', 'typeName', 'serial']}]
      }

      socket.emit('datasource', args, (res) => {
        resolve(res)
      })
    })
  };

  return (
    <Card marginTop={5} marginLeft={5}>
      <CardBody>
        <Button marginLeft={2} colorScheme='blue' onClick={() => navigate('/admin/digitalAccounts/giftCardsCodes')} mb={5}>
          Ver Códigos
        </Button>
        <TableContainer>
          <DataTable size={'sm'} columns={columns} data={data} dataFetch={dataFetch}/>
        </TableContainer>
      </CardBody>
    </Card>
  )
}

export default GiftCards