import CD, {zeroPad} from "react-countdown";

export default function CountDown({createdAt, seconds = 1,...props}) {

  const renderer = ({minutes, seconds, completed}) => {
    if (completed) {
      // Render a completed state
      return "Ha expirado el tiempo y no llegó un código de inicio de sesión";
    } else {
      // Render a countdown
      return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    }
  };

  console.log(Date.now(), createdAt)
  return (<CD {...props} renderer={renderer} date={createdAt + (1000 * seconds)}/>)

}