import React, {useEffect, useState} from "react";
import fondo from "../../assets/top-movie/fondo2.png"
import './style.css'

import {
  Box, Button,
  Center,
  Grid,
  GridItem,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import logo from "../../assets/top-movie/logo.png"
import header from "../../assets/top-movie/header2.png"
import {useLocation, useParams} from "react-router";
import {useAppDataStore} from "../../mobx/appDataStore";
import axios from "axios";

const setBackground = () => {

  // Create our stylesheet
  const style = document.createElement('style')
  style.innerHTML =
    `body {
      background: url("${fondo}")  no-repeat center center fixed;
      color: white;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
  }
  
   @media screen and (max-width: 62em) {
   
      body {
      background-image: url("${fondo}");
            background-size: cover;
      } 
   }
  `

// Get the first script tag
  const ref = document.getElementById('root')

// Insert our new styles before the first script tag
  ref.parentNode.insertBefore(style, ref)
}

const RedeemTopMovie = () => {
  const appDataStore = useAppDataStore()
  const [redeemData, setRedeemData] = useState()
  const location = useLocation()
  const {accountData, webToken} = location.state
  const socket = appDataStore.socket

  useEffect(() => {
    document.title = 'Disfruta tu cuenta! - Top Movie'
    setBackground()
    fetchData()
  }, []);

  const downloadInfo = async () => {
    window.open(`/api/v1/gift-cards/download/${accountData.code}`)
  }

  const reSend = async () => {
    const res = await appDataStore.post(`/gift-cards/re-send/${webToken}`)
    alert(res.message)
  }

  const fetchData = () => {
    socket.emit('redeem/info', {webToken: webToken}, (res) => {
      setRedeemData(res.redeem)
    })
  }

  return (
    <>
      <Grid
        templateColumns='repeat(2, 1fr)'
        gridTemplateColumns={'25% 1fr'}
        w={'95vw'}>
        <GridItem>
          <Center>
            <Image src={logo} alt={'Has adquirido una gift card'}/>
          </Center>
        </GridItem>
        <GridItem>
          <Center>
            <Image src={header} maxWidth={{base: '100%', lg: '80%'}} alt={'Has adquirido una gift card'}/>
          </Center>
        </GridItem>
      </Grid>
      <Box marginBottom={'30px'}>
        <Center>
          <Box maxWidth={'70%'} padding={'40px'} background={'blackAlpha.600'} borderRadius={'35px'}
               fontSize={{base: '1em', lg: 'larger'}}
               fontWeight={'bold'}>
            <Center style={{textAlign: 'center'}}>
              La vigencia de duración de tu plataforma de entretenimiento es de 27 a 30 días a
              partir de este momento,
              recuerda que es un ACCESO ÚNICO para (1) solo dispositivo
            </Center>
          </Box>
        </Center>
      </Box>
      <SimpleGrid columns={{base: 1, lg: 3}}>
        <Box>
          <Center>
            <Box mb={3} w={'70%'} borderRadius={'25px'} paddingY={'10px'} fontSize={'2em'} fontWeight={'bold'}
                 color={'#442670'} backgroundColor={'#FFF'}>
              <Center>
                Datos de acceso
              </Center>
            </Box>
          </Center>

          <Center>
            <Box w={'70%'} marginBottom={'15px'} borderRadius={'25px'} paddingY={'10px'} fontSize={'1em'}
                 fontWeight={'bold'}
                 paddingX={'15px'}
                 color={'#442670'} backgroundColor={'#FFF'}>
              <p>Tipo de Cuenta:</p>
              <p>{accountData.digital_account}</p>
              <p>Usuario:</p>
              <p>{accountData.login}</p>
              <p>Clave:</p>
              <p>{accountData.password}</p>
              <p>Perfil:</p>
              <p>{accountData.screen_profile}</p>
              <p>Info del producto:</p>
              <p>{accountData.message}</p>
              <p>Inicio de sesión:</p>
              <p><a href={accountData?.url}>{accountData?.url}</a></p>
              <p>Instrucciones de uso:</p>
              <p>{redeemData?.GiftCardCode.instructionsUrl &&
                <a target={'_blank'}
                   href={redeemData?.GiftCardCode.instructionsUrl}>{redeemData?.GiftCardCode.instructionsUrl}</a>}</p>
              <p>Info:</p>
              <p>{redeemData?.GiftCardCode.productInfo}</p>
              <Center>
                <Button colorScheme='' borderRadius={'20px'} backgroundColor={'#442670'} onClick={reSend}
                        color={'#FFF'}>Reenviar via whatsapp</Button>
              </Center>
            </Box>
          </Center>
        </Box>
        <Box>
          <Center>
            <Box w={'70%'} borderRadius={'25px'} paddingY={'10px'} fontSize={'2em'} fontWeight={'bold'}
                 color={'#442670'} backgroundColor={'#FFF'}>
              <Center style={{textAlign: 'center'}}>
                IMPORTANTE VER
              </Center>
            </Box>
          </Center>
          {accountData.instructions && <Center>
            <Box marginTop={'15px'} w={'70%'} borderRadius={'25px'} paddingY={'10px'} fontSize={'1em'}
                 fontWeight={'bold'}
                 paddingX={'15px'}
                 color={'#442670'} backgroundColor={'#FFF'}>
              <a rel={'noreferrer'} target={'_blank'} href={accountData.instructions}>
                <Center>pulsa AQUÍ para ver:</Center>
                <Center>GUIA DEL PRODUCTO</Center>
              </a>
            </Box>
          </Center>}


          <Center>
            <Box marginBottom={'15px'} marginTop={'15px'} w={'70%'} borderRadius={'25px'} paddingY={'10px'}
                 fontSize={'1em'}
                 fontWeight={'bold'}
                 paddingX={'15px'}
                 color={'#442670'} backgroundColor={'#FFF'}>
              <a rel={'noreferrer'} target={'_blank'} href={'https://topsmovie.com/terminos-y-condiciones/'}>
                <Center>pulsa AQUÍ para ver: </Center>
                <Center>TERMINOS Y CONDICIONES</Center>
              </a>
            </Box>
          </Center>

        </Box>
        <Box><Center>
          <Box w={'70%'} borderRadius={'25px'} paddingY={'10px'} fontSize={'2em'} fontWeight={'bold'}
               color={'#442670'} backgroundColor={'#FFF'}>
            <Center>
              Soporte
            </Center>
            <Center fontSize={'large'}><a rel={'noreferrer'} target={'_blank'} href={'tel://573161584590'}>(Agreganos a
              tus contactos)</a></Center>
          </Box>
        </Center>
          <Center>
            <Box marginTop={'15px'} w={'70%'} borderRadius={'25px'} paddingY={'10px'} fontSize={'1em'}
                 fontWeight={'bold'}
                 paddingX={'15px'}
                 color={'#442670'} backgroundColor={'#FFF'}>
              <Center>Whatsapp: <a rel={'noreferrer'} target={'_blank'} href={'https://wa.me/573161584590'}>+57 316 158
                4590</a></Center>
            </Box>
          </Center>
          <Center>
            <Box marginTop={'15px'} w={'70%'} borderRadius={'25px'} paddingY={'10px'} fontSize={'1em'}
                 fontWeight={'bold'}
                 paddingX={'15px'}
                 color={'#442670'} backgroundColor={'#FFF'}>
              <Center>Telegram: @topsmovietv</Center>
            </Box>
          </Center>
        </Box>
      </SimpleGrid>
    </>
  )

}

export default RedeemTopMovie
