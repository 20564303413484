import {
  Box,
  Button, ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Heading, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader,
  PopoverTrigger,
  Stack,
  StackDivider,
  Text
} from "@chakra-ui/react";
import {observer} from "mobx-react";
import moment from "moment";
import {useAppDataStore} from "../mobx/appDataStore";
import UAParser from 'ua-parser-js'

moment.locale('es');

function AlertDetail({loginAttempt}) {
  const parser = new UAParser(loginAttempt.userAgent);
  const userAgent = parser.getResult();

  return (
    <Popover
      placement='bottom'
      closeOnBlur={true}
    >
      <PopoverTrigger>
        <Button size={'xs'} ml={3}>detalles</Button>
      </PopoverTrigger>
      <PopoverContent color='white' bg='blue.800' borderColor='blue.800'>
        <PopoverHeader pt={4} fontWeight='bold' border='0'>
          {loginAttempt.loginLink?.username} {loginAttempt.loginLink?.profile}

        </PopoverHeader>
        <PopoverArrow bg='blue.800'/>
        <PopoverCloseButton/>
        <PopoverBody>
          <Text size={'xs'}>Código ingresado: {loginAttempt.tvCode}</Text>
          <Text size={'xs'}>IP: {loginAttempt.sourceIp}</Text>
          {loginAttempt.ipData ?
            <Text size={'xs'}>Ubicación: {loginAttempt.ipData.country}/{loginAttempt.ipData.city}</Text>
            : null}
          <Text
            size={'xs'}>Dispositivo: {userAgent.device.type} {userAgent.device.vendor} {userAgent.device.model}</Text>
          <Text size={'xs'}>Navegador: {userAgent.browser.name} {userAgent.browser.major}</Text>
          <Text size={'xs'}>OS: {userAgent.os.name} {userAgent.os.version}</Text>
          <Text size={'xs'} mt={3}>{loginAttempt.message}</Text>

        </PopoverBody>
        <PopoverFooter
          border='0'
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          pb={4}
        >
          <Box>{moment(loginAttempt.createdAt).fromNow()}</Box>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

export default observer(function Alerts() {
  const appDataStore = useAppDataStore()
  return (
    <Card marginTop={5} marginRight={5} marginLeft={3}>
      <CardHeader>
        <Heading size='md'>Notificaciones</Heading>
      </CardHeader>
      <CardBody minH={650} maxH={650} overflowY={'scroll'}>
        <Stack divider={<StackDivider/>} spacing='4'>
          {appDataStore.alerts.slice().reverse().map(loginAttempt => {
            return (
              <Box>
                <Heading size='xs'
                         color={loginAttempt.status === 'SUCCESS' ? 'green' : loginAttempt.soundAlert ? 'red' : 'orange'}
                         textTransform='uppercase'
                         display={'inline'}>
                  {loginAttempt.status === 'SUCCESS' ? 'EXITOSO' : loginAttempt.soundAlert ? 'ERROR' : 'ALERTA'}
                </Heading>
                <Text pl={3} pt='0' fontSize='xs' display={'inline'}>
                  {loginAttempt.loginLink?.username} {loginAttempt.loginLink?.profile}
                </Text>
                <Text pt='2' fontSize='sm'>
                  {loginAttempt.message}
                </Text>
                <Text as={'i'} pl={0} pt='0' fontSize='xs'>
                  {moment(loginAttempt.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </Text>
                <AlertDetail loginAttempt={loginAttempt}/>
              </Box>
            )
          })}
        </Stack>

      </CardBody>
    </Card>
  )
})