import {Outlet} from "react-router-dom";
import React, {useContext, createContext} from "react";
import wallpaper1 from "../assets/backgrounds/admin-background.png"
import {Grid, GridItem} from "@chakra-ui/react";
import Alerts from "./alerts";
import Header from "./header";

const LayoutAdmin = () => {

  // Create our stylesheet
  const style = document.createElement('style')
  style.innerHTML =
    `body {
      background-image: url("${wallpaper1}");
      color: purple;
  }`

// Get the first script tag
  const ref = document.getElementById('root')

// Insert our new styles before the first script tag
  ref.parentNode.insertBefore(style, ref)
  return (
    <Grid
      templateAreas={
        `"header header"
          "main alerts"
          `
      }
      gridTemplateRows={'90px 1fr 90px'}
      gridTemplateColumns={'1fr 350px'}
      h='200px'
      gap='1'
      color='blackAlpha.700'
    >
      <GridItem area={'header'}>
        <Header/>
      </GridItem>
      <GridItem area={'main'}>
        <Outlet/>
      </GridItem>
      <GridItem area={'alerts'}>
        <Alerts/>
      </GridItem>
    </Grid>
  )

}

export default LayoutAdmin