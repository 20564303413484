import React, {useEffect, useState} from "react";
import Button from "../../components/button";
import {Box, CardBody, Center, Image, SimpleGrid} from "@chakra-ui/react";
import {useAppDataStore} from "../../mobx/appDataStore";
import moment from "moment";

const Screen = ({service, fetchData}) => {
  const appDataStore = useAppDataStore()
  const {socket} = appDataStore
  const [ticker, setTicker] = useState(0)

  const codeReady = () => {
    socket.emit('services/code/ready', {}, (data) => {
      fetchData()
    })
  }

  const tickerToString = () => {
    const minutes = `0${Math.floor(ticker / 60)}`.substr(-2);
    const seconds = `0${ticker - minutes * 60}`.substr(-2)

    if (ticker > 0) return `00:${minutes}:${seconds}`
    return 'código expirado'
  }

  useEffect(() => {

    if (!service.codeGeneratedDate) return
    const endDate = moment(service.codeGeneratedDate).add(2, 'minutes')

    const interval = setInterval(() => {
      const diff = endDate.diff(moment(), 'seconds')
      setTicker(diff)
    }, 1000)

  }, [service.codeGeneratedDate]);
  return (
    <CardBody>
      <Center>
        <SimpleGrid>
          <Box>
            <Image src={service.sessionUrl}/>
          </Box>

          <Box>
            <Center>
              <p className={"counter"}>{tickerToString()}</p>
            </Center>
            <Button onClick={codeReady} title="Ya ingrese el codigo"/>

          </Box>
        </SimpleGrid>
      </Center>
    </CardBody>
  )
}

export default Screen