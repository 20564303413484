import {Outlet, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import wallpaper1 from "../assets/backgrounds/admin-background.png";
import {
  AbsoluteCenter,
  Box, Button,
  Card,
  CardBody, Center, FormControl, FormErrorMessage, FormHelperText, FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Stack,
  Text
} from "@chakra-ui/react";
import {Field, Form, Formik} from "formik";
import {useAppDataStore} from "../mobx/appDataStore";
import {action} from "mobx";

const Login = () => {

  const appDataStore = useAppDataStore()
  const navigate = useNavigate()

  const [twoFactor, setTwoFactor] = useState(false)
  // Create our stylesheet
  const style = document.createElement('style')

  style.innerHTML =
    `body {
      background-image: url("${wallpaper1}");
      color: purple;
  }`

// Get the first script tag
  const ref = document.getElementById('root')

// Insert our new styles before the first script tag
  ref.parentNode.insertBefore(style, ref)

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Correo electrónico requerido';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'El correo electrónico es inválido';
    }

    if (!values.password) {
      errors.password = 'La contraseña es requerida';
    }

    if (twoFactor && !values.otp.match(/[0-9]{6}/)) {
      errors.otp = 'El código OTP ingresado no es válido';
    }

    return errors;
  }

  const onSubmit = async ({email, password, otp}, actions) => {
    try {
      await appDataStore.login({email, password, otp})
      navigate('/admin')
    } catch (err) {
      if (err.response.status === 519) {
        setTwoFactor(true)
      } else {
        actions.setErrors({email: ' ', password: ' ', otp: ' ', general: 'Los datos ingresados no son válidos'})
      }
    }

    actions.setSubmitting(false)
  }
  return (
    <Center>
      <Box w={'600px'} pt={200}>
        <Card pt={50} pb={50}>
          <CardBody>
            <Formik
              initialValues={{email: '', password: '', otp: ''}}
              validate={validate}
              onSubmit={onSubmit}
            >
              {(props) => (
                <Form>
                  <Field name='email'>
                    {({field, form}) => (
                      <FormControl isInvalid={form.errors.email && form.touched.email}>
                        <FormLabel>Correo Electrónico</FormLabel>
                        <Input {...field} placeholder='Correo Electrónico'/>
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name='password'>
                    {({field, form}) => (
                      <FormControl pt={5} isInvalid={form.errors.password && form.touched.password}>
                        <FormLabel>Contraseña</FormLabel>
                        <Input {...field} placeholder='Contraseña' type={'password'}/>
                        <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {twoFactor ? <Field name='otp'>
                    {({field, form}) => (
                      <FormControl isInvalid={form.errors.otp && form.touched.otp}>
                        <FormLabel>Otp</FormLabel>
                        <Input {...field} placeholder='Otp'/>
                        <FormErrorMessage>{form.errors.otp}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field> : null}
                  <Text color={'red'}>{props.errors.general}</Text>
                  <Button
                    mt={4}
                    colorScheme='teal'
                    isLoading={props.isSubmitting}
                    type='submit'
                  >
                    Iniciar sesión
                  </Button>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Box>
    </Center>
  )

}

export default Login