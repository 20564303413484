import {
  TableContainer,
  CardBody,
  Card,
  Button,
  useToast,
} from '@chakra-ui/react'
import {useNavigate} from "react-router-dom";
import {DataTable} from "../components/dataTable";
import {createColumnHelper} from "@tanstack/react-table";
import {useAppDataStore} from "../mobx/appDataStore";
import {useEffect, useRef, useState} from "react";

const columnHelper = createColumnHelper();


const GiftCardsCodes = () => {
  const appDataStore = useAppDataStore()
  const {socket} = appDataStore
  const [data, setData] = useState([])
  const toast = useToast()
  const btnCreateRef = useRef()
  const btnCreateRef2 = useRef()
  const [refreshDependencies, setRefreshDependencies] = useState()
  const navigate = useNavigate()
  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    document.title = 'Gift Cards redimidas'
  }, []);


  const fetchData = () => {
    socket.emit('extension/list', {}, (res) => {
      setData(res.sockets)
      console.log(res.sockets)
    })
  }

  const columns = [
    columnHelper.accessor('id',
      {cell: (info) => info.getValue(), header: "ID"}),

    columnHelper.accessor('code',
      {cell: (info) => info.getValue(), header: "Código"}),

    columnHelper.accessor('typeId',
      {cell: (info) => info.getValue(), header: "ID Tipo"}),

    columnHelper.accessor('typeName',
      {cell: (info) => info.getValue(), header: "Tipo"}),

    columnHelper.accessor('status',
      {cell: (info) => info.getValue(), header: "Estado"}),

    columnHelper.accessor('serial',
      {cell: (info) => info.getValue(), header: "Serial"}),

    columnHelper.accessor('createdAt',
      {cell: (info) => Object.datetime(info.getValue()), header: "Fecha creación"}),

    columnHelper.accessor('updatedAt',
      {cell: (info) => Object.datetime(info.getValue()), header: "Ultima modificación"}),
  ]

  const dataFetch = async (
    records,
    currentPage,
    order,
    search,
    filter,
    attributes
  ) => {
    return new Promise(resolve => {
      const args = {
        model: 'GiftCardCode',
        records,
        currentPage,
        order,
        search,
        filter,
        attributes,
        //include: [{model: 'GiftCardCode', attributes: ['code', 'typeName', 'serial']}]
      }
      socket.emit('datasource', args, (res) => {
        resolve(res)
      })
    })
  };

  useEffect(() => {
    fetchData()
  }, []);

  useEffect(() => {
    socket.on('giftCards/updated', () => {

    })

    return () => {
      socket.off('giftCards/updated')
    }
  }, []);

  const uploadFile = (event, type) => {
    setUploading(true)
    const files = event.currentTarget.files
    socket.emit(`giftCards/${type}`, {file: files[0], filename: files[0].name, type}, function (res) {
      setUploading(false)
      setRefreshDependencies(Math.random())
      if (res.result === 'error') {
        return toast({
          title: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    })
  }

  return (
    <Card marginTop={5} marginLeft={5}>
      <CardBody>
        <input ref={btnCreateRef} type="file" className={'d-none'} style={{display: 'none'}}
               onChange={(e) => uploadFile(e, 'upload')}/>
        <input ref={btnCreateRef2} type="file" className={'d-none'} style={{display: 'none'}}
               onChange={(e) => uploadFile(e, 'associate')}/>

        <Button marginLeft={2} colorScheme='blue' onClick={() => navigate('/admin/digitalAccounts/giftCards')} mb={5}>
          Ver Redenciones
        </Button>
        <Button marginLeft={2} isLoading={uploading} colorScheme='blue' onClick={() => btnCreateRef.current.click()}
                mb={5}>
          Ingresar GiftCards
        </Button>
        <Button marginLeft={2} isLoading={uploading} colorScheme='blue' onClick={() => btnCreateRef2.current.click()}
                mb={5}>
          Asociar masivo
        </Button>
        <TableContainer>
          <DataTable size={'sm'} columns={columns} data={data} dataFetch={dataFetch}
                     refreshDependencies={refreshDependencies}/>
        </TableContainer>
      </CardBody>
    </Card>
  )
}

export default GiftCardsCodes