import {
  TableContainer,
  CardBody,
  Card,
  Button,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Image, Center, Flex, Spacer, Box, Input, Grid, SimpleGrid, InputGroup, InputRightAddon, Spinner,
} from '@chakra-ui/react'
import {DataTable} from "../components/dataTable";
import {createColumnHelper} from "@tanstack/react-table";
import {useAppDataStore} from "../mobx/appDataStore";
import {useEffect, useRef, useState} from "react";
import {BsCashCoin} from "react-icons/bs"
import {MdOutlineDone, MdOutlineError} from "react-icons/md"
import {FaMoneyCheckAlt} from "react-icons/fa";
import {FaMoneyCheckDollar} from "react-icons/fa6";
import {CloseIcon, DeleteIcon, DownloadIcon} from "@chakra-ui/icons";

const columnHelper = createColumnHelper();

const CreateLink = ({onSuccess}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const cancelRef = useRef()
  const appDataStore = useAppDataStore()
  const {socket} = appDataStore
  const [serviceTypes, setServiceTypes] = useState([])
  const [serviceTypeId, setServiceTypeId] = useState(null)
  useEffect(() => {
    socket.emit('serviceTypes/get', {}, (res) => {
      setServiceTypes(res.serviceTypes)
    })
  }, []);

  const sucessClick = () => {
    onSuccess(serviceTypeId)
    onClose()
  }
  return (
    <>
      <Button colorScheme='blue' onClick={onOpen} mb={5}>
        Crear Link
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Crear Link
            </AlertDialogHeader>

            <AlertDialogBody>
              <Select
                value={serviceTypeId}
                onChange={(e) => setServiceTypeId(e.target.value)}
                placeholder='Seleccione un tipo'>
                {serviceTypes.map(serviceType => {
                  return <option key={`serviceTypeId_${serviceType.id}`}
                                 value={serviceType.id}>{serviceType.label} ${Object.cash(serviceType.price)}</option>
                })}
              </Select>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme='blue' onClick={sucessClick} ml={3}>
                Crear
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

const DisplayInfo = ({row, fetchData}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const cancelRef = useRef()
  const [sendingImage, setSendingImage] = useState(false);
  const appDataStore = useAppDataStore()
  const toast = useToast()
  const {socket} = appDataStore
  const [service, setService] = useState(row)

  const handleChange = async (e) => {
    const [item] = await e.clipboardData.items

    if (item.type.indexOf("image") < 0) {
      e.preventDefault()
      return toast({
        title: 'Solo se acepta pegar imágenes',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
    setSendingImage(true)
    const file = item.getAsFile()
    socket.emit("upload", {
      file,
      meta: {id: service.id, name: file.name, mime: file.type, type: 'sessionCode'}
    }, function (res) {
      setSendingImage(false)
      if (res.result === 'error') {
        return toast({
          title: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      setService(res.service)
      return toast({
        title: 'Se ha enviado el código con éxito',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    })
  }

  const handleClose = () => {
    fetchData()
    onClose()
  }

  return (
    <>
      <Button colorScheme='blue' onClick={onOpen}>
        <FaMoneyCheckDollar/>
      </Button>

      <Modal
        size={'6xl'}
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Comprobante de pago servicio # {service.id}</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <Flex>
              <Box p='4'>
                Comprobante de pago
                <Center>
                  <Image
                    width={500}
                    src={service.voucherUrl}
                    fallbackSrc='https://fakeimg.pl/500x600?text=Sin imagen'/>
                </Center>
              </Box>
              <Spacer/>
              <Box p='4'>
                Código de sesión
                <Center>
                  <SimpleGrid>
                    <Box>
                      <Image
                        width={500}
                        src={sendingImage ? null : service.sessionUrl}
                        fallbackSrc='https://fakeimg.pl/500x600?text=Cargando...'/>
                    </Box>
                    <Box>
                      <InputGroup size='md'>
                        <Input
                          placeholder='Click aquí y pega la imagen'
                          onPaste={handleChange}
                          disabled={sendingImage}/>
                        {sendingImage ? <InputRightAddon children={<Spinner/>}/> : null}
                      </InputGroup>
                    </Box>
                  </SimpleGrid>
                  <Spacer/>
                </Center>
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose}>Cerrar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const ServiceList = () => {
  const appDataStore = useAppDataStore()
  const socket = appDataStore.socket
  const [data, setData] = useState([])
  const toast = useToast()

  useEffect(() => {
    socket.on('services/updated', () => {
      fetchData()
    })
    return () => {
      socket.off('services/updated')
    }
  }, []);

  const setPaid = async (row) => {
    socket.emit('services/paid', row, function (res) {
      if (res.result === 'error') {
        return toast({
          title: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      fetchData()
    })
  }
  const setCompleted = async (row) => {
    socket.emit('services/completed', row, function (res) {
      if (res.result === 'error') {
        return toast({
          title: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      fetchData()
    })
  }

  const setError = async (row) => {
    socket.emit('services/error', row, function (res) {
      if (res.result === 'error') {
        return toast({
          title: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      fetchData()
    })
  }

  const setHidden = async (row) => {
    socket.emit('services/hide', row, function (res) {
      if (res.result === 'error') {
        return toast({
          title: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      fetchData()
    })
  }

  const fetchData = () => {
    socket.emit('services/get', {}, (res) => {
      setData(res.services)
    })
  }

  const createLink = (serviceTypeId) => {
    socket.emit('services/create', {serviceTypeId}, function (res) {
      if (res.result === 'error') {
        return toast({
          title: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      fetchData()
    })
  }

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    return toast({
      title: 'Copiado',
      description: text,
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  const viewVoucher = async (row) => {

  }
  const columns = [
    columnHelper.accessor('id',
      {cell: (info) => info.getValue(), header: "ID"}),
    columnHelper.accessor('ServiceType.label',
      {cell: (info) => info.getValue(), header: "Tipo de servicio"}),
    columnHelper.accessor('ServiceType.price',
      {cell: (info) => Object.cash(info.getValue()), header: "Precio"}),
    columnHelper.accessor('customerName',
      {cell: (info) => info.getValue(), header: "Cliente"}),
    columnHelper.accessor('customerPhone',
      {cell: (info) => info.getValue(), header: "Teléfono"}),
    columnHelper.accessor('customerCountry',
      {cell: (info) => info.getValue(), header: "País"}),
    columnHelper.accessor('token',
      {
        cell: (info) => {
          const serviceKey = info.cell.row.original.ServiceType?.key
          const token = info.getValue()
          const url = `${window.location.origin}/store/${token}/roblox/`
          return <Button colorScheme='green' size={'sm'} onClick={() => copyToClipboard(url)}>Copiar</Button>
        }, header: "URL"
      }),
    columnHelper.accessor('paymentDate',
      {cell: (info) => Object.datetime(info.getValue()), header: "Fecha pago"}),
    columnHelper.accessor('completedDate',
      {cell: (info) => Object.datetime(info.getValue()), header: "Fecha completado"}),
    columnHelper.accessor('statusLabel',
      {cell: (info) => info.getValue(), header: "Estado"}),
    columnHelper.accessor('',
      {cell: (info) => <Button color={'blue'} onClick={() => setPaid(info.row.original)}><BsCashCoin/></Button>, header: "Pagado"}),
    columnHelper.accessor('',
      {
        cell: (info) => <Button color={'green'} onClick={() => setCompleted(info.row.original)}><MdOutlineDone/></Button>,
        header: "listo"
      }),
    columnHelper.accessor('',
      {
        cell: (info) => <Button color={'red'} onClick={() => setError(info.row.original)}><CloseIcon/></Button>,
        header: "Error"
      }),
    columnHelper.accessor('',
      {
        cell: (info) => <DisplayInfo fetchData={fetchData} row={info.row.original}/>,
        header: "Comprobante"
      }),
    columnHelper.accessor('',
      {
        cell: (info) => <Button color={'red'} onClick={() => setHidden(info.row.original)}><DeleteIcon/></Button>,
        header: "Finalizar"
      }),
  ]

  useEffect(() => {
    fetchData()
  }, []);

  return (
    <Card m={10}>
      <CardBody>
        <CreateLink onSuccess={createLink}/>
        <TableContainer>
          <DataTable size={'sm'} columns={columns} data={data}/>
        </TableContainer>
      </CardBody>
    </Card>
  )
}

export default ServiceList