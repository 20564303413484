import {DeleteIcon, DownloadIcon} from "@chakra-ui/icons";
import {
  TableContainer,
  CardBody,
  Card,
  Button,
  useToast,
  Box,
} from '@chakra-ui/react'
import {useNavigate} from "react-router-dom";
import {DataTable} from "../components/dataTable";
import {createColumnHelper} from "@tanstack/react-table";
import {useAppDataStore} from "../mobx/appDataStore";
import {useEffect, useRef, useState} from "react";
import {HiRefresh} from "react-icons/hi";

const columnHelper = createColumnHelper();


const ConnectedExtensions = () => {
  const appDataStore = useAppDataStore()
  const {socket} = appDataStore
  const [data, setData] = useState([])
  const toast = useToast()
  const btnCreateRef = useRef()
  const navigate = useNavigate()
  useEffect(() => {
    document.title = 'Extensiones conectadas'
  }, []);

  useEffect(() => {
    socket.on('extensions/update', () => {
      fetchData()
    })

    return () => {
      socket.off('extensions/update')
    }
  }, []);


  const fetchData = () => {
    socket.emit('extension/list', {}, (res) => {
      setData(res.sockets)
      console.log(res.sockets)
    })
  }


  const blacklist = (id) => {
    socket.emit('extension/status', {id}, (res) => {
      fetchData()
    })
  }

  const force = (id, forced) => {
    socket.emit('extension/force', {id, force: !forced}, (res) => {
      fetchData()
    })
  }

  const columns = [
    columnHelper.accessor('id',
      {cell: (info) => info.getValue(), header: "ID"}),

    columnHelper.accessor('stats.netflix.success',
      {cell: (info) => info.getValue(), header: "N"}),

    columnHelper.accessor('stats.amazon.success',
      {cell: (info) => info.getValue(), header: "A"}),

    columnHelper.accessor('stats.netflix.error',
      {cell: (info) => info.getValue(), header: "Err N"}),

    columnHelper.accessor('stats.amazon.error',
      {cell: (info) => info.getValue(), header: "Err A"}),

    columnHelper.accessor('task',
      {cell: (info) => info.getValue(), header: "Total"}),

    columnHelper.accessor('createdAt',
      {cell: (info) => info.getValue(), header: "Conexión"}),

    columnHelper.accessor('options.processNetflix',
      {cell: (info) => {
          const state = info.getValue()
          return state ? 'SI' : 'NO'
        }, header: "Netflix"}),
    columnHelper.accessor('options.processPrime',
      {cell: (info) => {
          const state = info.getValue()
          return state ? 'SI' : 'NO'
        }, header: "Amazon"}),
    columnHelper.accessor('disabled',
      {
        cell: (info) => {
          const state = info.getValue()
          return state ? 'NO' : 'SI'
        }, header: "Habilitada"
      }),
    columnHelper.accessor('forced',
      {
        cell: (info) => {
          const state = info.getValue()
          return state ? 'SI' : 'NO'
        }, header: "Forzada"
      }),
    columnHelper.accessor('',
      {
        cell: (info) => <Button color={'green'} onClick={() => blacklist(info.row.original.id)}><HiRefresh/></Button>,
        header: "Estado"
      }),
    columnHelper.accessor('',
      {
        cell: (info) => <Button color={'green'}
                                onClick={() => force(info.row.original.id, info.row.original.forced)}><HiRefresh/></Button>,
        header: "Forzar"
      }),
  ]

  useEffect(() => {
    fetchData()
  }, []);

  return (
    <Card marginTop={5} marginLeft={5}>
      <CardBody>
        <Button marginLeft={2} colorScheme='blue' onClick={() => navigate('/admin/digitalAccounts/list')} mb={5}>
          Ver Cuentas
        </Button>
        <TableContainer>
          <DataTable size={'sm'} columns={columns} data={data}/>
        </TableContainer>
      </CardBody>
    </Card>
  )
}

export default ConnectedExtensions