import React, {useEffect, useState} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Img, Input, Select, useToast} from "@chakra-ui/react";
import {Field, Form, Formik} from "formik";
import {useAppDataStore} from "../../mobx/appDataStore";
import {useNavigate} from "react-router-dom";
import intro from "../../assets/intro.jpeg"

const CreateLink = ({}) => {
  const appDataStore = useAppDataStore()
  const toast = useToast()
  const navigate = useNavigate()

  const {socket} = appDataStore
  const [serviceTypeId, setServiceTypeId] = useState()
  const [serviceTypes, setServiceTypes] = useState([])
  useEffect(() => {
    socket.emit('serviceTypes/get', {}, (res) => {
      setServiceTypes(res.serviceTypes)
    })
  }, []);


  const onSubmit = (values, actions) => {
    const {serviceTypeId, customerName, customerPhone} = values

    socket.emit('services/create', {serviceTypeId, customerName, customerPhone}, function (res) {
      actions.setSubmitting(false)

      if (res.result === 'error') {
        return toast({
          title: res.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      window.location = `/store/${res.service.token}/roblox/`
    })
  }

  return (
    <div className="center">
      <Img p={50} pt={1} pb={5} src={intro} />
      <Box p={10} pt={1}>
        <Formik
          initialValues={{}}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Form>
              <Field name='customerName'>
                {({field, form}) => (
                  <FormControl isInvalid={form.errors.customerName && form.touched.customerName}>
                    <FormLabel>Nombre</FormLabel>
                    <Input {...field} placeholder='Nombre'/>
                    <FormErrorMessage>{form.errors.customerName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name='customerPhone'>
                {({field, form}) => (
                  <FormControl isInvalid={form.errors.customerPhone && form.touched.customerPhone}>
                    <FormLabel>Teléfono</FormLabel>
                    <Input {...field} placeholder='Teléfono'/>
                    <FormErrorMessage>{form.errors.customerPhone}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name='customerCountry'>
                {({field, form}) => (
                  <FormControl isInvalid={form.errors.customerCountry && form.touched.customerCountry}>
                    <FormLabel>País</FormLabel>
                    <Select
                      {...field}
                      placeholder='Seleccione un tipo'>
                      <option value={'colombia'}>Colombia</option>
                      <option value={'ecuador'}>Ecuador</option>
                      <option value={'venezuela'}>Venezuela</option>
                      <option value={'paraguay'}>Paraguay</option>
                    </Select>
                    <FormErrorMessage>{form.errors.customerCountry}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name='serviceTypeId'>
                {({field, form}) => (
                  <FormControl isInvalid={form.errors.serviceTypeId && form.touched.serviceTypeId}>
                    <FormLabel>Tipo de producto</FormLabel>
                    <Select
                      {...field}
                      placeholder='Seleccione un tipo'>
                      {serviceTypes.map(serviceType => {
                        return <option key={`serviceTypeId_${serviceType.id}`}
                                       value={serviceType.id}>{serviceType.label} ${Object.cash(serviceType.price)}</option>
                      })}
                    </Select>
                    <FormErrorMessage>{form.errors.serviceTypeId}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Button
                mt={4}
                colorScheme='blue'
                isLoading={props.isSubmitting}
                type='submit'
              >
                Iniciar proceso de compra
              </Button>
            </Form>
          )}
        </Formik>


      </Box>
    </div>)
}
export default CreateLink