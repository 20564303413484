import {Outlet} from "react-router-dom";
import React, {useEffect, useState} from "react";
import fondo from "../../assets/cuentas/fondo.webp"
import bannerTop5 from "../../assets/cuentas/bannerTop5.webp"
import top1 from "../../assets/cuentas/top1.webp"
import top2 from "../../assets/cuentas/top2.webp"
import top3 from "../../assets/cuentas/top3.webp"
import top4 from "../../assets/cuentas/top4.webp"
import top5 from "../../assets/cuentas/top5.webp"
import logo from "../../assets/cuentas/logo-png.webp"
import howToCel from "../../assets/cuentas/como_cel.webp"
import howToPc from "../../assets/cuentas/como_pc.webp"

import {Box, Button, Card, Center, Grid, Hide, Image, Show, SimpleGrid} from "@chakra-ui/react";

const setBackground = () => {

  // Create our stylesheet
  const style = document.createElement('style')
  style.innerHTML =
    `body {
      background-image: url("${fondo}");
      color: purple;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
  }
  
   @media screen and (max-width: 815px) {
   
      body {
            background-size: contain;
      } 
   }
  `

// Get the first script tag
  const ref = document.getElementById('root')

// Insert our new styles before the first script tag
  ref.parentNode.insertBefore(style, ref)
}

const LayoutNetflix = () => {

  const [ready, setReady] = useState(false)
  useEffect(() => {
    document.title = 'Tienda Digital Netflix'
    setBackground()
  }, []);

  if (!ready) {
    return (
      <Box>
        <Center>
          <Box hideBelow='lg' paddingTop={10} paddingX={40}>
            <img src={howToPc}/>
          </Box>
          <Box hideFrom={'lg'} paddingTop={10} paddingX={10}>
            <img src={howToCel}/>
          </Box>

        </Center>
        <Center>
          <Box paddingY={7} display={'block'}>
            <Button onClick={()=> setReady(true)} paddingX={20}>Continuar</Button>
          </Box>
        </Center>
      </Box>
    )
  }
  return (
    <>
      <Center>
        <Box w={['95%', '80%', 700]} pt={50}>
          <Center> <img src={logo}/>
          </Center>
          <Card
            boxShadow='dark-lg'
            border={'1px'}
            borderColor={'#FFFFFF'}
            color={'#FFFFFF'}
            background={'rgba(3, 0, 44, 0.5)'}
          >

            <Outlet/>
          </Card>
        </Box>
      </Center>
      <Center>
        <img src={bannerTop5} width={200}/>
      </Center>
      <SimpleGrid columns={[2, 2, 3, 5]} spacing={10}>
        <img src={top1}/>
        <img src={top2}/>
        <img src={top3}/>
        <img src={top4}/>
        <img src={top5}/>

      </SimpleGrid>
    </>
  )

}

export default LayoutNetflix