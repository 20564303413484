import {ChevronDownIcon} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody, Center,
  Flex, FormControl, FormErrorMessage, FormLabel,
  Grid,
  GridItem, Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select,
  Spacer, Text, useDisclosure, useToast
} from "@chakra-ui/react";
import {useFormik} from "formik";
import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {useAppDataStore} from "../mobx/appDataStore";

function ChangePasswordModal() {
  const appDataStore = useAppDataStore()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const toast = useToast()
  const [error, setError] = useState(null)
  const formik = useFormik({
    onSubmit: async (values) => {
      try {
        setError(null)
        const res = await appDataStore.post('/auth/editPassword', values)
        toast({
          title: 'Exito!',
          description: "La contraseña fué actualizada con éxito.",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        onClose()
      } catch (err) {
        const {errors, error} = err.response.data
        if (errors) formik.setErrors(errors)
        setError(error)
      }

    },
    initialValues: {}
  })
  useEffect(() => {
    formik.resetForm()
  }, []);
  return (
    <>
      <MenuItem onClick={onOpen}>Cambiar contraseña</MenuItem>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Cambiar contraseña</ModalHeader>
          <ModalCloseButton/>
          <ModalBody pb={6}>
            <FormControl isInvalid={!!formik.errors['data.password']}>
              <FormLabel>Contraseña actual</FormLabel>
              <Input
                type={'password'}
                name={'password'}
                onChange={formik.handleChange}
                value={formik.values.password}
              />
            </FormControl>

            <FormControl mt={4} isInvalid={!!formik.errors['data.newPassword']}>
              <FormLabel>Nueva contraseña</FormLabel>
              <Input
                type={'password'}
                name={'newPassword'}
                onChange={formik.handleChange}
                value={formik.values.newPassword}
              />
            </FormControl>

            <FormControl mt={4} isInvalid={!!formik.errors['data.newPassword2']}>
              <FormLabel>Repetir nueva contraseña</FormLabel>
              <Input
                type={'password'}
                name={'newPassword2'}
                onChange={formik.handleChange}
                value={formik.values.newPassword2}
              />
            </FormControl>
            <Text color={'red'}>{error}</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Cerrar
            </Button>
            <Button variant='outline' onClick={formik.submitForm}>Cambiar contraseña</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function Enable2FModal() {
  const appDataStore = useAppDataStore()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const toast = useToast()
  const [error, setError] = useState(null)
  const [twoFactor, setTwoFactor] = useState()


  const formik = useFormik({
    onSubmit: async (values) => {
      try {
        setError(null)
        const res = await appDataStore.post('/auth/validateTwoFactor', values)
        toast({
          title: 'Exito!',
          description: "Se ha vinculado autenticación de doble factor.",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        onClose()
      } catch (err) {
        const {errors, error} = err.response.data
        if (errors) formik.setErrors(errors)
        setError(error)
      }

    },
    initialValues: {}
  })

  const fetchData = async () => {
    const twoFactor = await appDataStore.post('/auth/enableTwoFactor', {})
    setTwoFactor(twoFactor.secret)
    formik.setFieldValue('secret', twoFactor.secret.secret)
  }

  useEffect(() => {
    if (isOpen) {
      setTwoFactor(null)
      formik.resetForm()
      fetchData()
    }

  }, [isOpen]);

  return (
    <>
      <MenuItem onClick={onOpen}>Autenticación doble factor</MenuItem>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Autenticación de doble factor</ModalHeader>
          <ModalCloseButton/>
          <ModalBody pb={6}>
            {twoFactor ?
              <Box>
                <Center>
                  <img src={twoFactor.qr} alt={'qr'}/>
                </Center>
                <Center mt={3}>
                  <Button as={'a'} href={twoFactor.uri}>Agregar a authenticator</Button>
                </Center>
              </Box> : null}

            <FormControl isInvalid={!!formik.errors['data.otp']}>
              <FormLabel>OTP</FormLabel>
              <Input
                type={'otp'}
                name={'otp'}
                onChange={formik.handleChange}
                value={formik.values.otp}
              />
            </FormControl>
            <Text color={'red'}>{error}</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Cerrar
            </Button>
            <Button variant='outline' onClick={formik.submitForm}>Vincular 2 factor</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default observer(function Header() {
  const appDataStore = useAppDataStore()
  const {headerData} = appDataStore

  return (
    <Card m={5}>
      <CardBody>
        <Grid templateColumns='repeat(6, 1fr)' gap={6}>
          <GridItem>
            <Box>Cuentas disponibles: <Box display={'inline'}
                                           color={headerData.availableAccounts > 5 ? 'green' : 'red'}>
              <b>{headerData.availableAccounts}</b>
            </Box>
            </Box>
          </GridItem>
          <GridItem>
            <Box>Extensiones conectadas: <Box display={'inline'}
                                              color={headerData.extensions > 0 ? 'green' : 'red'}>
              <b>{headerData.extensions}</b>
            </Box>
            </Box>
          </GridItem>
          <GridItem>
            <Box>Cuentas con alertas: <Box display={'inline'}
                                           color={headerData.alerts > 0 ? 'red' : 'green'}><b>{headerData.alerts}</b></Box></Box>
          </GridItem>
          <GridItem colSpan={3}>
            <Flex>
              <Spacer/>
              <Menu>
                <MenuButton variant={'outline'} as={Button} rightIcon={<ChevronDownIcon/>}>
                  Menú
                </MenuButton>
                <MenuList>
                  <ChangePasswordModal/>
                  <Enable2FModal/>
                  <MenuItem onClick={appDataStore.logout}>Cerrar sesión</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  )
})